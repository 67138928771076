import { MovimientosFilterActionType } from './actions';
import { MovimientosFilterActionTypes, MovimientosFilterStateType } from './constants';

const INIT_STATE: MovimientosFilterStateType = {
    defaultPortfolioId: 0,
    defaultPortfolioGroupId: 0,
};

const MovimientosFilter = (state: MovimientosFilterStateType = INIT_STATE, action: MovimientosFilterActionType<string | boolean | null>) => {
    switch (action.type) {
        case MovimientosFilterActionTypes.CHANGE_PORTFOLIOGROUPID:
                return {
                    ...state,
                    defaultPortfolioGroupId: action.payload,
                };
                case MovimientosFilterActionTypes.CHANGE_PORTFOLIOID:
                    return {
                        ...state,
                        defaultPortfolioId: action.payload,
                    };                
        default:
            return state;
    }
};

export default MovimientosFilter;
