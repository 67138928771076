import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { Accordion, AccordionContext, Card, Collapse, useAccordionButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type CustomToggleProps = {
  children: React.ReactNode;
  eventKey: string;
  containerClass: string;
  linkClass: string;
  callback?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};
const CustomToggle = ({ children, eventKey, containerClass, linkClass, callback }: CustomToggleProps) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => callback && callback(e));

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <h5 className={containerClass}>
      <Link
        to="#"
        className={classNames(linkClass, {
          collapsed: !isCurrentEventKey,
        })}
        onClick={decoratedOnClick}
      >
        {children}
      </Link>
    </h5>
  );
};

type FilterCollapserProps = {
  collapsed?: boolean;
  collapsedTextDescription?: string;
  className?: string;
  children: React.ReactNode;
};

const FilterCollapser = ({ collapsed, children, className, collapsedTextDescription }: FilterCollapserProps) => {
  const [open, setOpen] = useState<boolean>(!collapsed);

  const toggleMenuItem = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const status = !open;
    setOpen(status);
    return false;
  };

  const getTitleText = () => {
    let text = 'Filtros';
    if (!open && collapsedTextDescription) {
      text = collapsedTextDescription;
    }
    return text;
  };

  return (<>
    <Accordion defaultActiveKey="0" id="accordion" className={classNames('collapser-accordion', className)}>
      <Card className="mb-0">
        <Card.Header>
          <CustomToggle
            eventKey='0'
            containerClass="m-0"
            linkClass={classNames('custom-accordion-title d-block py-1', !open ? 'text-dark' : '')}
            callback={(event) => { toggleMenuItem(event) }}
          >
            <i className="uil-filter"></i>
            <span> {getTitleText()} </span>
            <i className={classNames('accordion-arrow mdi', open ? 'mdi-chevron-down' : 'mdi-chevron-right')}></i>
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={'0'} in={open}>
          <div>
            <Card.Body className='pt-2 pb-0'>{children}</Card.Body>
          </div>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </>)
};

export default FilterCollapser;
