import { forwardRef, Fragment, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Field } from "formik";
import { useTranslation } from 'react-i18next';
import { useApiCalls } from "helpers/api/useApiCalls";
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import FormInstrumentsItem from "./form-instruments-item";
import useResolveInstrumentDisplayLabel from "./form-instruments-display";
import { ItemsValuesContext } from "./ItemsValuesContext";
import AsyncTypeaheadInput from "../AsyncTypeaheadInput";
import { odataInstrumentsSearchQuery } from "helpers/api/utils";
import { useRedux } from "hooks";
import { InstrumentTypesEnum } from "types/InstrumentTypesEnum";

type FormInstrumentFieldProps = {
    name: any;
    label: any;
    inputGroupPrepend?: any;
    containerClass?: any;
    onComponentLoaded?: any;
    excluirMonedas?: boolean;
    soloInstrumentosDelCliente?: boolean;
    onChange?: (item: any) => void;
    disabled?: boolean;
};
const FormInstrumentField = forwardRef((props: FormInstrumentFieldProps, ref: any) => {
    const { t } = useTranslation();
    const { formikProps, itemsValues } = useContext(ItemsValuesContext);
    const apiCalls = useApiCalls()
    const defaultRef = useRef();
    const { getDisplayLabel } = useResolveInstrumentDisplayLabel();
    const resolvedRef: any = null || defaultRef;
    // const [currentQuery, setCurrentQuery] = useState<string | null>(null);
    const [isSearching, setIsSearching] = useState(false);
    const [shownResults, setShownResults] = useState<number | null>(null);
    const [options, setOptions] = useState<any[]>([]);
    const [selectedOption, setSelectedOption] = useState<any | null>(null);

    useImperativeHandle(ref, () => ({
        clear() {
            if (resolvedRef.current) {
                resolvedRef.current.clear();
            }
        },
        setSelectedItem(selectedItem: any) {
            if (resolvedRef?.current?.setSelectedItem) {
                resolvedRef.current.setSelectedItem(selectedItem);
            }
        }
    }));

    //al cargarse el componente
    useEffect(() => {
        if (formikProps.values && formikProps.values[props.name]) {
            setSelectedOption(itemsValues[props.name]);
        }
    }, []);

    //al iniciarse typeahead
    // useEffect(() => {
    //     if (resolvedRef.current) {
    //         if (formikProps.values && formikProps.values[props.name]) {
    //             resolvedRef.current.setState({
    //                 selected: [options.filter((x: any) => x.id == formikProps.values[props.name])[0]]
    //             });
    //         }
    //         else {
    //             resolvedRef.current.focus();
    //             if (!isSearching) {
    //                 handleSearch('');
    //             }
    //         }
    //     }
    // }, [resolvedRef.current]);

    const handleSearch = (query: string) => {
        let _builder: string[] = [];

        //string match query
        if (query) {
            _builder.push(odataInstrumentsSearchQuery(query));
        }
        //instrument type
        if (formikProps.values?.instrumentTypeId) {
            _builder.push(`InstrumentsType/Id eq ${formikProps.values.instrumentTypeId}`);
        }
        //market
        if (formikProps.values?.marketId) {
            _builder.push(`Markets/Id eq ${formikProps.values.marketId}`);
        }
        //
        if (props.soloInstrumentosDelCliente) {
            let codCliente = apiCalls.active().codCliente;
            _builder.push(`Client/Id eq ${codCliente}`);
        }

        //build query
        let finalQuery = _builder.map(x => `(${x})`).join(' and ');

        let promise = apiCalls.Instrumentos.getInstrumentsOData({ $orderby: 'Denomination', $filter: finalQuery });
        if (props.excluirMonedas) {
            promise.then((response: any) => {
                response.data = response.data.filter((x: any) => {
                    let instrumentType = x?.instrumentsType?.type;
                    if (instrumentType == InstrumentTypesEnum.SPOT) {
                        return false;
                    }
                    return true;
                });
                return response;
            })
        }
        return promise;
    };

    return (
        <>
            <Field name={props.name} render={({ form, meta }: any) => {
                return (<AsyncTypeaheadInput
                    ref={resolvedRef}
                    name={props.name}
                    containerClass={props.containerClass}
                    label={props.label}
                    apiEndpoint={handleSearch}
                    delay={1}
                    promptText='Ingrese Symbol, Ticker o Descripción para iniciar la búsqueda...'
                    onChange={(item: any) => {
                        form.setFieldValue(props.name, item?.id);
                        if (props.onChange) {
                            setTimeout(() => {
                                if (props.onChange) {
                                    props.onChange(item);
                                }
                            }, 0);
                        }
                    }}
                    onComponentLoaded={(componentRef: any) => {
                        if (props.onComponentLoaded) {
                            props.onComponentLoaded(componentRef);
                        }
                    }}
                    displayLabel={getDisplayLabel}
                    defaultInputValue={getDisplayLabel(itemsValues[props.name]) || ''}
                    renderMenuItemChildren={(option: any, { text }: any) => (<FormInstrumentsItem className='mb-2' option={option} searchText={text} />)}
                    inputGroupPrepend={props.inputGroupPrepend}
                    formControlFeedback={
                        <Form.Control.Feedback type="invalid" style={{ display: meta.error ? 'block' : 'none' }}>
                            {meta.error}
                        </Form.Control.Feedback>
                    }
                    disabled={props.disabled}
                />);
            }}
            />
        </>
    );
});
export default FormInstrumentField;