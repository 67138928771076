import config from "config";
import { useEffect, useState } from "react";
import ReactGA from 'react-ga';
import { useLocation } from "react-router-dom";
import { GoogleAnalyticsEventType } from "types/GoogleAnalyticsEventType";

export function useGoogleAnalytics() {
    const location = useLocation();
    const [hasMeasurementId, setHasMeasurementId] = useState<boolean>(!!config?.REACT_APP_GA_MEASUREMENT_ID);
    const [trackingPages, setTrackingPages] = useState<boolean>(false);

    useEffect(() => {
        if (trackingPages) {
            trackPageView();
        }
    }, [location]);

    const trackPageView = () => {
        if (hasMeasurementId) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }

    const initPageTracking = () => {
        setTrackingPages(true);
    }

    return { initPageTracking };
}

export function useGoogleAnalyticsEvents() {
    const [hasMeasurementId, setHasMeasurementId] = useState<boolean>(!!config?.REACT_APP_GA_MEASUREMENT_ID);

    const trackEvent = (event: any) => {
        if (hasMeasurementId) {
            ReactGA.event(event);
        }
    }

    return { trackEvent };
}

