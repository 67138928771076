import { useCallback, useState } from 'react';

type NotificationDictionary = {
    [key: string]: boolean;
};
export default function useToggleNotification(initialState: NotificationDictionary = {}): [NotificationDictionary, (key: string) => void, (key: string) => void] {
    const [states, setStates] = useState(initialState);

    const showNotification = useCallback((key: string) => {
        states[key] = true;
        setStates(states);
    }, []);

    const hideNotification = useCallback((key: string) => {
        states[key] = false;
        setStates(states);
    }, []);

    return [states, showNotification, hideNotification];
}
