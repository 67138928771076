import { Button, ButtonProps } from "react-bootstrap";

export const ButtonAction = (props: ButtonProps) => {
    const onClick = (e: any) => {
        if (props?.onClick) {
            props.onClick(e);
        }
        e.stopPropagation();
    };
    return <Button {...props} color="primary" size="sm" variant="outline-primary" className="text-dark ms-1" onClick={onClick}>
        {props.children}
    </Button>
}
