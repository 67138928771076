import { NotificationActionTypes, NotificationData } from './constants';

export type NotificationActionType<TPayload> = {
    type:
    | NotificationActionTypes.ADD_NOTIFICATION
    | NotificationActionTypes.DELETE_NOTIFICATION
    payload?: TPayload;
};

const addNotificationDefaultValues: NotificationData = {
    type: 'info',
    delay: 5000,
}
export const addNotification = (notification: NotificationData): NotificationActionType<NotificationData> => ({
    type: NotificationActionTypes.ADD_NOTIFICATION,
    payload: {
        id: Math.floor(Math.random() * 100).toString(),
        ...addNotificationDefaultValues,
        ...notification
    },
});
export const deleteNotification = (notification: NotificationData): NotificationActionType<NotificationData> => ({
    type: NotificationActionTypes.DELETE_NOTIFICATION,
    payload: notification,
});

export default { addNotification, deleteNotification };