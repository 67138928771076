enum ModalsActionTypes {
    ADD_MODAL = '@@modals/ADD_MODAL',
    DELETE_MODAL = '@@modals/DELETE_MODAL',
}

export type ModalStateType = {
    currentZIndex:number
};

export { ModalsActionTypes };
