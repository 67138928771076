import { useInternationalization } from "@progress/kendo-react-intl";
import { NumberFormatOptions } from '@progress/kendo-react-intl';
import { kendoCurrencyDefaultFormat, kendoNumberDefaultFormat } from "appConstants";

export default function useFormatterService() {
    const intl = useInternationalization();

    const quantityFormatOptions: NumberFormatOptions = {
        style: "decimal",
        maximumFractionDigits: 8
    };
    const currencyFormatOptions: NumberFormatOptions = kendoCurrencyDefaultFormat;

    function currencyFormat(num: number | undefined, format: NumberFormatOptions | undefined = kendoCurrencyDefaultFormat): string {
        var result = num !== undefined ? intl.formatNumber(num, format) : '';
        return result != 'NaN' ? result : '';
    }

    function quantityFormat(num: number | undefined, format: NumberFormatOptions | undefined = kendoNumberDefaultFormat): string {
        var result = num !== undefined ? intl.formatNumber(num, format || quantityFormatOptions) : '';
        return result;
    }

    return {
        currencyFormat,
        quantityFormat,
        quantityFormatOptions,
        currencyFormatOptions
    };
}