import { Form, InputGroup } from "react-bootstrap";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { NumberFormatOptions } from '@progress/kendo-react-intl';

const NumericField = ({ as, md, controlId, label, name, type, inputGroupPrepend, disabled, onChange, spinners, value, onBlur, valid, error }: any) => {
    //TODO: verificar si nunca lo vamos a utilizar
    const formatOptions: NumberFormatOptions = {
        style: "decimal",
        maximumFractionDigits: 8
    };

    return (
        <Form.Group as={as} md={md} controlId={controlId} className="mb-3">
            <Form.Label>{label}</Form.Label>
            <InputGroup>
                {inputGroupPrepend}
                <NumericTextBox
                    className='einv-kendo-form-control'
                    name={name}
                    value={value}
                    min={0}
                    format='n4'
                    inputType='text'
                    fillMode='outline'
                    rounded='small'
                    disabled={disabled}
                    spinners={spinners !== undefined ? spinners : false} //EINV-612 -> se previene que se incremente/decremente con botones
                    step={0} //EINV-612 -> se previene que se incremente/decremente con keyPress
                    onChange={onChange}
                    onBlur={onBlur}
                    valid={valid}
                />
                <Form.Control.Feedback type="invalid" style={{ display: error ? 'block' : 'none' }}>
                    {error}
                </Form.Control.Feedback>
            </InputGroup>
        </Form.Group>
    );
};

NumericField.defaultProps = {
    type: "text",
    inputGroupPrepend: null
};

export default NumericField;
