import { Popover } from "react-bootstrap";

const popOver = (body?: string, header?: string) => {
  return (
    <Popover>
      {(header && <Popover.Header as="h3">{header}</Popover.Header>)}
      <Popover.Body>{body}</Popover.Body>
    </Popover>
  )
};

export default popOver;
