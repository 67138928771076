import { Form, InputGroup } from "react-bootstrap";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { einvDateFormat } from "appConstants";

const today = new Date();
const DateInput = ({ as, md, controlId, label, name, type, inputGroupPrepend, filterDate, minDate, maxDate, onChange, mostrarErrores, value }: any) => {
    const parseDateString = (value: string): Date => {
        let defaultValue: Date = today;
        if (!value) {
            return defaultValue;
        }
        //Se utilizar minutos y segundos estandarizados a fines de comparación entre fechas
        let momentValue = moment(value)
            .set('hour', today.getHours())
            .set('minute', today.getMinutes())
            .set('second', today.getSeconds())
            .set('millisecond', today.getMilliseconds());

        if (!momentValue.isValid()) {
            return defaultValue;
        }
        return momentValue.toDate();
    };

    return (
        <Form.Group as={as} md={md} controlId={controlId} className="mb-3">
            <Form.Label>{label}</Form.Label>
            <InputGroup>
                {inputGroupPrepend}
                <DatePicker
                    customInput={
                        <input
                            type="text"
                            className='form-control date w-100'
                            value={value}
                        />}
                    className={'form-control'}
                    selected={parseDateString(value)}
                    dayClassName={(date: Date) =>
                        today.toDateString() === date.toDateString() ? 'datepicker-today-custom-class' : ''
                    }
                    onChange={(date) => {
                        if (onChange) {
                            onChange(date);
                        }
                    }}
                    timeFormat='hh:mm a'
                    dateFormat={einvDateFormat}
                    minDate={minDate}
                    maxDate={maxDate}
                    filterDate={filterDate}
                    autoComplete="off"
                />
            </InputGroup>
        </Form.Group>
    );
};

DateInput.defaultProps = {
    type: "text",
    inputGroupPrepend: null
};

export default DateInput;
