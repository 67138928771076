/* Layout types and other constants */

import enFlag from '../assets/images/flags/us.jpg';
import germanyFlag from '../assets/images/flags/germany.jpg';
import italyFlag from '../assets/images/flags/italy.jpg';
import spainFlag from '../assets/images/flags/spain.jpg';
import russiaFlag from '../assets/images/flags/russia.jpg';
import { Language } from "redux/layout/constants";

export enum LayoutTypes {
    LAYOUT_VERTICAL = 'vertical',
    LAYOUT_HORIZONTAL = 'topnav',
    LAYOUT_DETACHED = 'detached',
    LAYOUT_FULL = 'full',
}

export enum LayoutColor {
    LAYOUT_COLOR_LIGHT = 'light',
    LAYOUT_COLOR_DARK = 'dark',
}

export enum LayoutWidth {
    LAYOUT_WIDTH_FLUID = 'fluid',
    LAYOUT_WIDTH_BOXED = 'boxed',
}

export enum SideBarTheme {
    LEFT_SIDEBAR_THEME_DEFAULT = 'default',
    LEFT_SIDEBAR_THEME_LIGHT = 'light',
    LEFT_SIDEBAR_THEME_DARK = 'dark',
}

export enum SideBarWidth {
    LEFT_SIDEBAR_TYPE_FIXED = 'fixed',
    LEFT_SIDEBAR_TYPE_CONDENSED = 'condensed',
    LEFT_SIDEBAR_TYPE_SCROLLABLE = 'scrollable',
}

export enum Languages {
    SPANISH= 'es-AR',
    ENGLISH = 'en-us',
    GERMAN= 'ger',
    ITALIAN= 'ita',
    RUSSIAN= 'rus',
}

// get the languages
export const ENGLISH: Language = {
    id: 'en',
    isoCode: Languages.ENGLISH,
    name: 'English',
    flag: enFlag,
};
export const GERMAN: Language = 
{
    id: 'de',
    isoCode: Languages.GERMAN,
    name: 'German',
    flag: germanyFlag,
};
export const ITALIAN: Language = {
    id: 'it',
    isoCode: Languages.ITALIAN,
    name: 'Italian',
    flag: italyFlag,
};
export const SPANISH: Language = {
    id: 'es',
    isoCode: Languages.SPANISH,
    name: 'Español',
    flag: spainFlag,
};
export const RUSSIAN: Language = {
    id: 'ru',
    isoCode: Languages.RUSSIAN,
    name: 'Russian',
    flag: russiaFlag,
};