import { AxiosResponse } from 'axios';
import { CashTransactionType, HoldingsTransactionType } from 'types/movements/MovementsType';
import { sortArrayByDateProperty } from './utils';

export function useApiCallsInterceptors() {
    const absoluteValue = (value: any) => {
        //bug: quantity viene con valores negativos y solo pueden impactarse valores positivos. 
        //Se aplica valor absoluto, solo para quantity tenga un valor != 0 (ver https://www.w3schools.com/jsref/tryit.asp?filename=tryjsref_abs2) 
        if (!value) {
            return value;
        }
        return Math.abs(value);
    }

    return {
        Instrumentos: {
            ModifyODataInstruments: (promise: Promise<AxiosResponse<any, any>>) => {
                promise.then((response: any) => {
                    if (response?.data?.length) {
                        response.data.forEach((instrument: any) => {
                            //trim instrument denomination
                            //Solución a EINV-554. 
                            //TODO: Debería solucionarse en API
                            if (instrument?.denomination) {
                                instrument.denomination = instrument.denomination.trim();
                            }
                        });
                    }
                });
            },
        },
        Portfolio: {
            ModifyOrderTotalValue: (promise: Promise<AxiosResponse<any, any>>) => {
                promise.then((response: any) => {
                    if (response?.data?.length) {
                        response.data = sortArrayByDateProperty(response.data, 'date');
                    }
                });
            },
        },
        Movimientos: {
            ModifyQuantity: (promise: Promise<AxiosResponse<any, any>>) => {
                promise.then((response: any) => {
                    let transactions: any[] = response.data;
                    transactions.forEach(transaction => {
                        //cashs
                        transaction.cashTransactions?.forEach((value: CashTransactionType) => {
                            value.quantity = absoluteValue(value.quantity);
                        });
                        //holdings
                        transaction.holdingTransactions?.forEach((value: HoldingsTransactionType) => {
                            value.quantity = absoluteValue(value.quantity);
                        });
                    });
                    return transactions;
                });
            },
            MapAgentsAccounts: (promise: Promise<AxiosResponse<any, any>>) => {
                promise.then((response: any) => {
                    let agentAccounts: any[] = response.data;
                    /*
                    Para facilitar el manejo de estos datos, se transformará el resultado y se devolverá una lista anidada con el siguiente formato: 
                    [
                        {
                            //...
                            description: 'Tipo de agente',
                            agents: [
                                {
                                    //...
                                    description: 'Agente',
                                    clientAccounts: [
                                        {
                                            //...
                                            description: 'Cuenta del cliente',
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                    */
                    let agentTypes: any[] = [];
                    //Recorro toda la info que se devuelve
                    agentAccounts.forEach(agentAccount => {
                        //Por cada elemento
                        if (agentAccount) {
                            let agent = agentAccount.agent;
                            let currentAccount = agentAccount.currentAccount;
                            //Reviso el tipo de agente
                            if (agent?.agentType) {
                                //Si no existe en el objeto que se devolverá, lo agrego
                                let agentType = agent.agentType;
                                if (agentTypes.filter(x => x.id == agentType.id).length) {
                                    agentType = agentTypes.filter(x => x.id == agentType.id)[0];
                                }
                                else {
                                    agentTypes.push(agentType);
                                }

                                //Limpio el campo agentType para evitar un bucle infinito
                                agent.agentType = undefined;

                                //Para ese tipo de agente inicializo los agentes, y me fijo si el agente del elemento actual está en esa lista. Si no está, lo agrego
                                agentType.agents = agentType.agents || [];
                                if (agentType.agents.filter((x: any) => x.id == agent.id).length) {
                                    agent = agentType.agents.filter((x: any) => x.id == agent.id)[0];
                                }
                                else {
                                    agentType.agents.push(agent);
                                }
                            }
                            //Con el tipo de agente y agente inicializado, agrego la clientAccount si no está en las cuentas para ese agente
                            if (agent && currentAccount) {
                                agent.clientAccounts = agent.clientAccounts || [];
                                if (!agent.clientAccounts.filter((x: any) => x.id == currentAccount.id).length) {
                                    agent.clientAccounts.push(currentAccount);
                                }
                            }
                        }
                    });
                    response.data = agentTypes;
                    return agentTypes;
                });
            },
        }
    };
}