import { UserPreferencesStateType } from "types/UserPreferencesStateType";
import { UserPreferencesActionType } from "./actions";
import { UserPreferencesActionTypes, UserPreferencesData } from "./constants";

const INIT_STATE: UserPreferencesData = {
    selectedDashboardLayout: 'A',
    hidePortfolios: [],
    hideGroups: [],
};

const UserPreferences = (state: UserPreferencesStateType = INIT_STATE, action: UserPreferencesActionType<UserPreferencesData>) => {
    switch (action.type) {
        case UserPreferencesActionTypes.USER_PREFERENCES_UPDATED:
            console.log('USER_PREFERENCES_UPDATED', { ...state, ...action.payload, });
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default UserPreferences;
