import { GridDataStateChangeEvent, GridProps, GridToolbar } from '@progress/kendo-react-grid';
import { process as kendoProcess } from '@progress/kendo-data-query';
import CustomKendoGrid from './CustomKendoGrid';
import { useGridConfig } from 'hooks';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormInput } from 'components/form';
import { useForm } from 'react-hook-form';

interface BasicCustomKendoGridProps extends GridProps {
    searchFields?: string[];
};

const BasicCustomKendoGrid = (props: BasicCustomKendoGridProps) => {
    const methods = useForm({ shouldUseNativeValidation: true });
    const { handleSubmit, register, control, formState: { errors } } = methods;
    const { dataState, pageable, setDataState, setPercentage, setSearch, rowRender, setSort } = useGridConfig();

    useEffect(() => {
        setSort(props.sort || []);
    }, []);

    const dataStateChange = (e: GridDataStateChangeEvent) => {
        setDataState(e.dataState);
    };

    return (
        <CustomKendoGrid
            style={{ minHeight: '500px', maxHeight: '1000px', zoom: '75%'}}
            pageable={true}
            {...props}
            // @ts-ignore: Unreachable code error
            data={kendoProcess(props.data, dataState)}
            sortable={true}
            resizable={true}
            {...dataState}
            onDataStateChange={dataStateChange}
            onSortChange={(e) => {
                setSort(e.sort);
            }}>
            {props.searchFields && (
                <GridToolbar>
                    <form className="row align-items-center justify-content-xl-start justify-content-between" onSubmit={handleSubmit(() => { })}>
                        <div className="d-flex align-items-center w-auto">
                            <label htmlFor="status-select" className="me-2">
                                Buscar
                            </label>
                            <FormInput
                                type="text"
                                key="buscar"
                                register={register}
                                name="buscar"
                                containerClass="w-300 mr-2"
                                errors={errors}
                                control={control}
                                onChange={(e) => {
                                    setSearch(e.target.value, props.searchFields || []);
                                }}
                            />
                        </div>
                    </form>
                </GridToolbar>
            )}

            {props.children}

        </CustomKendoGrid>
    );
};

export default BasicCustomKendoGrid;