import { useEffect, useState } from "react";

type LoadingDotsProps = {
  isLoading: boolean;
  children: React.ReactNode;
};

const LoadingDots = ({ isLoading, children }: LoadingDotsProps) => {
  const [text, setText] = useState<string>('');
  let intervalId: NodeJS.Timeout;

  useEffect(() => {
    if (isLoading) {
      initDots();
    }
    
    return () => {
      cancelDots();
    };
  }, [isLoading]);

  const initDots = () => {
    intervalId = setInterval(() => {
      setText((prev) => (prev.length < 3 ? prev + '.' : ''));
    }, 1000);
  };

  const cancelDots = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    setText(''); // Reset the text when not loading
  };

  return (
    <>
      {isLoading && <>{text}</>}
      {!isLoading && <>{children}</>}
    </>
  );
};

export default LoadingDots;
