
enum UserPreferencesActionTypes {
    USER_PREFERENCES_UPDATED = '@@userPreferencess/USER_PREFERENCES_UPDATED',
}

export type UserPreferencesData = {
    selectedDashboardLayout?: string,
    hidePortfolios?: string[],
    hideGroups?: string[],
};


export { UserPreferencesActionTypes };
