export const carteraReservaData = [
    { date: "2022-01-06T03:00:00.000Z", value: 1638691564.78 },
    { date: "2022-01-07T03:00:00.000Z", value: 1636016715.03 },
    { date: "2022-01-13T03:00:00.000Z", value: 1630680625.47 },
    { date: "2022-01-14T03:00:00.000Z", value: 1628355034.73 },
    { date: "2022-01-21T03:00:00.000Z", value: 1641432950.66 },
    { date: "2022-01-24T03:00:00.000Z", value: 1642294544.30 },
    { date: "2022-01-28T03:00:00.000Z", value: 1682245705.79 },
    { date: "2022-01-31T03:00:00.000Z", value: 1690791090.35 },
    { date: "2022-02-04T03:00:00.000Z", value: 1673720313.36 },
    { date: "2022-02-07T03:00:00.000Z", value: 1676194974.61 },
    { date: "2022-02-11T03:00:00.000Z", value: 1666417623.21 },
    { date: "2022-02-14T03:00:00.000Z", value: 1667825389.60 },
    { date: "2022-02-17T03:00:00.000Z", value: 1674555987.92 },
    { date: "2022-02-18T03:00:00.000Z", value: 1676454620.56 },
    { date: "2022-02-23T03:00:00.000Z", value: 1676175543.83 },
    { date: "2022-02-24T03:00:00.000Z", value: 1673382602.69 },
    { date: "2022-02-28T03:00:00.000Z", value: 1660315355.33 },
    { date: "2022-03-03T03:00:00.000Z", value: 1662208204.16 },
    { date: "2022-03-04T03:00:00.000Z", value: 1649760434.72 },
    { date: "2022-03-11T03:00:00.000Z", value: 1649409245.50 },
    { date: "2022-03-14T03:00:00.000Z", value: 1648223172.69 },
    { date: "2022-03-15T03:00:00.000Z", value: 1724442169.08 },
    { date: "2022-03-16T03:00:00.000Z", value: 1738759133.69 },
    { date: "2022-03-18T03:00:00.000Z", value: 1744521132.97 },
    { date: "2022-03-22T03:00:00.000Z", value: 1750775440.47 },
    { date: "2022-03-24T03:00:00.000Z", value: 1749321627.29 },
    { date: "2022-03-25T03:00:00.000Z", value: 1757308534.13 },
    { date: "2022-03-28T03:00:00.000Z", value: 1760189038.80 },
    { date: "2022-03-29T03:00:00.000Z", value: 1762324434.08 },
    { date: "2022-03-31T03:00:00.000Z", value: 1764856341.92 },
    { date: "2022-04-06T03:00:00.000Z", value: 1771492375.18 },
    { date: "2022-04-08T03:00:00.000Z", value: 1769991106.11 },
    { date: "2022-04-11T03:00:00.000Z", value: 1771851541.14 },
    { date: "2022-04-15T03:00:00.000Z", value: 1778880207.50 },
    { date: "2022-04-19T03:00:00.000Z", value: 1819715870.47 },
    { date: "2022-04-21T03:00:00.000Z", value: 1837091236.05 },
    { date: "2022-04-22T03:00:00.000Z", value: 1838094592.65 },
    { date: "2022-04-26T03:00:00.000Z", value: 1847688853.80 },
    { date: "2022-04-29T03:00:00.000Z", value: 1826183300.09 },
    { date: "2022-05-03T03:00:00.000Z", value: 1841905764.85 },
    { date: "2022-05-04T03:00:00.000Z", value: 1846831942.70 },
    { date: "2022-05-05T03:00:00.000Z", value: 1840694800.42 },
    { date: "2022-05-06T03:00:00.000Z", value: 1838146665.90 },
    { date: "2022-05-12T03:00:00.000Z", value: 1836159336.85 },
    { date: "2022-05-13T03:00:00.000Z", value: 1822280034.52 },
    { date: "2022-05-17T03:00:00.000Z", value: 1824908006.80 },
    { date: "2022-05-20T03:00:00.000Z", value: 1824039045.79 },
    { date: "2022-05-23T03:00:00.000Z", value: 1832450703.86 },
    { date: "2022-05-24T03:00:00.000Z", value: 1834988151.17 },
    { date: "2022-05-26T03:00:00.000Z", value: 1860088753.51 },
    { date: "2022-05-27T03:00:00.000Z", value: 1863381333.74 },
    { date: "2022-05-30T03:00:00.000Z", value: 1870638895.38 },
    { date: "2022-05-31T03:00:00.000Z", value: 1866352885.18 },
    { date: "2022-06-02T03:00:00.000Z", value: 1864946376.39 },
    { date: "2022-06-03T03:00:00.000Z", value: 1865773299.32 },
    { date: "2022-06-08T03:00:00.000Z", value: 1839910841.64 },
    { date: "2022-06-10T03:00:00.000Z", value: 1819448002.36 },
    { date: "2022-06-13T03:00:00.000Z", value: 1819285276.75 },
    { date: "2022-06-15T03:00:00.000Z", value: 1850778004.97 },
    { date: "2022-06-21T03:00:00.000Z", value: 1841748758.88 },
    { date: "2022-06-22T03:00:00.000Z", value: 1826512302.09 },
    { date: "2022-06-24T03:00:00.000Z", value: 1809173740.99 },
    { date: "2022-06-28T03:00:00.000Z", value: 1860758007.14 },
    { date: "2022-06-30T03:00:00.000Z", value: 1880413252.99 },
    { date: "2022-07-01T03:00:00.000Z", value: 1890415329.46 },
    { date: "2022-07-04T03:00:00.000Z", value: 1904029553.12 },
    { date: "2022-07-08T03:00:00.000Z", value: 1916537611.81 },
    { date: "2022-07-15T03:00:00.000Z", value: 1937082678.02 },
    { date: "2022-07-18T03:00:00.000Z", value: 1948521022.79 },
    { date: "2022-07-19T03:00:00.000Z", value: 1963742738.08 },
    { date: "2022-07-22T03:00:00.000Z", value: 1975470050.91 },
    { date: "2022-07-25T03:00:00.000Z", value: 1993477438.93 },
    { date: "2022-07-27T03:00:00.000Z", value: 2035537566.34 },
    { date: "2022-07-28T03:00:00.000Z", value: 2075808548.35 },
    { date: "2022-07-29T03:00:00.000Z", value: 2070616701.20 },
    { date: "2022-08-01T03:00:00.000Z", value: 2082438044.88 },
    { date: "2022-08-03T03:00:00.000Z", value: 2077227860.95 },
    { date: "2022-08-05T03:00:00.000Z", value: 2081194274.10 },
    { date: "2022-08-09T03:00:00.000Z", value: 2086317094.47 },
    { date: "2022-08-11T03:00:00.000Z", value: 2083733751.09 },
    { date: "2022-08-17T03:00:00.000Z", value: 2101821938.79 },
    { date: "2022-08-19T03:00:00.000Z", value: 2114933493.01 },
    { date: "2022-08-23T03:00:00.000Z", value: 2137166561.65 },
    { date: "2022-08-26T03:00:00.000Z", value: 2145738359.10 },
    { date: "2022-08-29T03:00:00.000Z", value: 2161356845.00 }
];