import { FilterDescriptor, SortDescriptor, State } from '@progress/kendo-data-query';
import { GridPagerSettings } from '@progress/kendo-react-grid';
import { cloneElement, useState } from 'react';

export default function useGridConfig(modal?: 'modal' | 'nomodal' | null ) {
    const gridWidth = (modal === 'modal')? 1080: 1548;
    const setPercentage: any = (percentage: number) => {
        return Math.round(gridWidth / 100) * percentage;
    };

    const [dataState, setDataState] = useState<State>({
        skip: 0,
        take: 10
    });    

    const pageable: GridPagerSettings = {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [10, 20, 50, 100, 200],
        previousNext: true
    };

    const setSort = (sort: SortDescriptor[]) => {
        setDataState({ 
            sort: sort,
            skip: 0,
            take: dataState.take,                      
        });     
    };

    const setSearch = (value: any, fields: string[], logic?: string ) => {
        if (value) {
            var filters: FilterDescriptor[] = [];
            for (var field in fields) {
                filters.push({ field: fields[field], operator: 'contains', value: value });
            }
            setDataState({ 
                skip: 0, 
                take: 10,
                filter: { 
                    logic: (logic == null || logic == 'or') ? 'or' : 'and', 
                    filters: filters } 
            });
        }
        else {
            setDataState({ skip: 0, take: 10 });
        }
    };

    const rowRender = (trElement: any, props: any) => {
        const background = { color: !props.dataItem.isAvailable ? "#cf3200" : "" };
        const trProps = { style: background };
        return cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };

    const rowRenderCanceled = (trElement: any, props: any) => {
        const background = { color: props.dataItem.isCanceled ? "#cf3200" : "" };
        const trProps = { style: background };
        return cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };

    return {dataState, pageable, setDataState, setPercentage, setSearch, setSort, rowRender, rowRenderCanceled};
}

