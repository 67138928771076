import Routes from 'routes/Routes';
import { useAuth0 } from "@auth0/auth0-react";
import { configureFakeBackend } from 'helpers';
import LoginAuth0 from "./pages/account/LoginAuth0";
import Loading from "./components/Loading";

// For Default import Saas.scss
import 'assets/scss/Saas.scss';
// import 'assets/scss/Creative.scss';
// import 'assets/scss/Modern.scss';

import 'react-bootstrap-typeahead/css/Typeahead.css';

const App = () => {
  configureFakeBackend();
  const { isLoading, isAuthenticated, error } = useAuth0();

  let _component =
    error ? <div>Oops... {error.message}</div> :
      isLoading ? <Loading /> :
        !isAuthenticated ? <LoginAuth0 /> :
          <Routes />;
          
  return _component;
};

export default App;
