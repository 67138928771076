import EINV_AuthProvider from './AuthProvider';
import EINV_IntlProvider from './IntlProvider';
import EINV_ReduxProvider from './ReduxProvider';
import EINV_ThemeProvider from './ThemeProvider';

const EINV_Providers = (props: any) => {
    return (
        <EINV_AuthProvider>
            <EINV_ReduxProvider>
                <EINV_ThemeProvider>
                    <EINV_IntlProvider>
                        {props.children}
                    </EINV_IntlProvider>
                </EINV_ThemeProvider>
            </EINV_ReduxProvider>
        </EINV_AuthProvider>
    )
}

export default EINV_Providers;