import { ModalsActionTypes } from './constants';

export type ModalActionType<TPayload> = {
    type:
    | ModalsActionTypes.ADD_MODAL
    | ModalsActionTypes.DELETE_MODAL
    payload?: TPayload;
};

const initModalZIndex: number = 10000;
export const addModal = (): ModalActionType<number> => ({
    type: ModalsActionTypes.ADD_MODAL,
});
export const deleteModal = (): ModalActionType<number> => ({
    type: ModalsActionTypes.DELETE_MODAL,
});

export default { addModal, deleteModal };