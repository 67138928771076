
enum MovimientosFilterActionTypes {
    CHANGE_PORTFOLIOID = '@@movimientosFilters/CHANGE_PORTFOLIOID',
    CHANGE_PORTFOLIOGROUPID = '@@movimientosFilters/CHANGE_PORTFOLIOGROUPID'
}

export type MovimientosFilterStateType = {
    defaultPortfolioId: number;
    defaultPortfolioGroupId: number;
};

export { MovimientosFilterActionTypes };
