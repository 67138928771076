export const carteraSegurosData = [
    { date: "2022-01-06T03:00:00.000Z", value: 948510141.08 },
    { date: "2022-01-07T03:00:00.000Z", value: 945534123.95 },
    { date: "2022-01-13T03:00:00.000Z", value: 924715031.32 },
    { date: "2022-01-14T03:00:00.000Z", value: 922708020.81 },
    { date: "2022-01-21T03:00:00.000Z", value: 946485493.24 },
    { date: "2022-01-24T03:00:00.000Z", value: 941785359.90 },
    { date: "2022-01-28T03:00:00.000Z", value: 994151455.10 },
    { date: "2022-01-31T03:00:00.000Z", value: 1006019581.48 },
    { date: "2022-02-04T03:00:00.000Z", value: 1015734542.06 },
    { date: "2022-02-07T03:00:00.000Z", value: 1016756372.69 },
    { date: "2022-02-11T03:00:00.000Z", value: 994125639.54 },
    { date: "2022-02-14T03:00:00.000Z", value: 986548234.42 },
    { date: "2022-02-17T03:00:00.000Z", value: 1003584458.86 },
    { date: "2022-02-18T03:00:00.000Z", value: 995709669.68 },
    { date: "2022-02-23T03:00:00.000Z", value: 977236105.42 },
    { date: "2022-02-24T03:00:00.000Z", value: 963641772.49 },
    { date: "2022-02-28T03:00:00.000Z", value: 946204783.71 },
    { date: "2022-03-03T03:00:00.000Z", value: 932711208.12 },
    { date: "2022-03-04T03:00:00.000Z", value: 930929229.99 },
    { date: "2022-03-11T03:00:00.000Z", value: 928399301.33 },
    { date: "2022-03-14T03:00:00.000Z", value: 920909868.51 },
    { date: "2022-03-15T03:00:00.000Z", value: 911303192.05 },
    { date: "2022-03-16T03:00:00.000Z", value: 920157877.91 },
    { date: "2022-03-18T03:00:00.000Z", value: 938436507.43 },
    { date: "2022-03-22T03:00:00.000Z", value: 938284510.49 },
    { date: "2022-03-24T03:00:00.000Z", value: 945944168.02 },
    { date: "2022-03-25T03:00:00.000Z", value: 942756754.18 },
    { date: "2022-03-28T03:00:00.000Z", value: 942260825.90 },
    { date: "2022-03-29T03:00:00.000Z", value: 941734276.65 },
    { date: "2022-03-31T03:00:00.000Z", value: 938361572.12 },
    { date: "2022-04-06T03:00:00.000Z", value: 935500524.47 },
    { date: "2022-04-08T03:00:00.000Z", value: 942169857.61 },
    { date: "2022-04-11T03:00:00.000Z", value: 932465820.35 },
    { date: "2022-04-15T03:00:00.000Z", value: 935503900.93 },
    { date: "2022-04-19T03:00:00.000Z", value: 951740306.10 },
    { date: "2022-04-21T03:00:00.000Z", value: 981095904.95 },
    { date: "2022-04-22T03:00:00.000Z", value: 984714698.77 },
    { date: "2022-04-26T03:00:00.000Z", value: 1000750642.65 },
    { date: "2022-04-29T03:00:00.000Z", value: 981765685.21 },
    { date: "2022-05-03T03:00:00.000Z", value: 985417684.26 },
    { date: "2022-05-04T03:00:00.000Z", value: 983740398.22 },
    { date: "2022-05-05T03:00:00.000Z", value: 988466967.48 },
    { date: "2022-05-06T03:00:00.000Z", value: 981691631.81 },
    { date: "2022-05-12T03:00:00.000Z", value: 968706567.08 },
    { date: "2022-05-13T03:00:00.000Z", value: 978989240.53 },
    { date: "2022-05-17T03:00:00.000Z", value: 967337439.23 },
    { date: "2022-05-20T03:00:00.000Z", value: 967653701.36 },
    { date: "2022-05-23T03:00:00.000Z", value: 980322159.31 },
    { date: "2022-05-24T03:00:00.000Z", value: 974562573.89 },
    { date: "2022-05-26T03:00:00.000Z", value: 983884157.41 },
    { date: "2022-05-27T03:00:00.000Z", value: 988884516.59 },
    { date: "2022-05-30T03:00:00.000Z", value: 986209822.86 },
    { date: "2022-05-31T03:00:00.000Z", value: 983135332.28 },
    { date: "2022-06-02T03:00:00.000Z", value: 983321476.60 },
    { date: "2022-06-03T03:00:00.000Z", value: 983765240.95 },
    { date: "2022-06-08T03:00:00.000Z", value: 953627655.65 },
    { date: "2022-06-10T03:00:00.000Z", value: 944966993.25 },
    { date: "2022-06-13T03:00:00.000Z", value: 966182786.91 },
    { date: "2022-06-15T03:00:00.000Z", value: 994537014.36 },
    { date: "2022-06-21T03:00:00.000Z", value: 973549199.01 },
    { date: "2022-06-22T03:00:00.000Z", value: 960575735.95 },
    { date: "2022-06-24T03:00:00.000Z", value: 947908408.54 },
    { date: "2022-06-28T03:00:00.000Z", value: 968140936.45 },
    { date: "2022-06-30T03:00:00.000Z", value: 962306713.09 },
    { date: "2022-07-01T03:00:00.000Z", value: 972497892.62 },
    { date: "2022-07-04T03:00:00.000Z", value: 984395522.77 },
    { date: "2022-07-08T03:00:00.000Z", value: 990870586.72 },
    { date: "2022-07-15T03:00:00.000Z", value: 980319435.60 },
    { date: "2022-07-18T03:00:00.000Z", value: 993490611.53 },
    { date: "2022-07-19T03:00:00.000Z", value: 996715242.78 },
    { date: "2022-07-22T03:00:00.000Z", value: 1019064964.63 },
    { date: "2022-07-25T03:00:00.000Z", value: 1025951007.86 },
    { date: "2022-07-27T03:00:00.000Z", value: 1087656720.57 },
    { date: "2022-07-28T03:00:00.000Z", value: 1113038966.45 },
    { date: "2022-07-29T03:00:00.000Z", value: 1102212184.86 },
    { date: "2022-08-01T03:00:00.000Z", value: 1106028392.62 },
    { date: "2022-08-03T03:00:00.000Z", value: 1102150230.99 },
    { date: "2022-08-05T03:00:00.000Z", value: 1106734496.89 },
    { date: "2022-08-09T03:00:00.000Z", value: 1101149998.24 },
    { date: "2022-08-11T03:00:00.000Z", value: 1108240984.11 },
    { date: "2022-08-17T03:00:00.000Z", value: 1111360015.20 },
    { date: "2022-08-19T03:00:00.000Z", value: 1147669004.09 },
    { date: "2022-08-23T03:00:00.000Z", value: 1162453300.69 },
    { date: "2022-08-26T03:00:00.000Z", value: 1183731090.41 },
    { date: "2022-08-29T03:00:00.000Z", value: 1174051359.47 },
    { date: "2022-08-31T03:00:00.000Z", value: 1161938193.29 }
];