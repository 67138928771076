import { LayoutTypes, LayoutColor, LayoutWidth, SideBarTheme, SideBarWidth, Languages } from 'appConstants';
import React from 'react';

enum NotificationActionTypes {
    ADD_NOTIFICATION = '@@notifications/ADD_NOTIFICATION',
    DELETE_NOTIFICATION = '@@notifications/DELETE_NOTIFICATION',
}

export type NotificationData = {
    id?: string,
    delay?: number,
    message?: string,
    body?: React.ReactNode,
    type?: 'success' | 'info' | 'warning' | 'danger',
    containerClass?: string,
};

export type NotificationStateType = {
    notifications: NotificationData[];
};


export { NotificationActionTypes };
