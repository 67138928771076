import { Navigate, useLocation } from 'react-router-dom';
import { APICore } from 'helpers/api/apiCore';
import { useRedux, useUser } from 'hooks';
import LoginAuth0 from "pages/account/LoginAuth0";
import { setClientAvailable, setClientName, setUserRole, setClientCurrencyType, setClientCounty } from 'redux/actions';
import { useEffect } from 'react';
import { RolesEnum } from 'types/Roles';
import { useApiCalls } from 'helpers/api/useApiCalls';

type PrivateRouteProps = {
    component: React.ComponentType;
    roles?: string;
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
    let location = useLocation();
    const { dispatch } = useRedux();
    const [loggedInUser] = useUser();
    const { Client } = useApiCalls();
    const api = new APICore();

    //Al ingresar a la ruta privada
    useEffect(() => {
        //actualizo variable de cliente habilitado
        dispatch(setUserRole(loggedInUser?.role));
    }, [!!loggedInUser?.profile?.clientAvailable, loggedInUser?.role]);

    useEffect(() => {
        if (api.isUserAuthenticated()) {
            //Si el rol no es comercial o admin, busco la información del cliente del usuario logueado
            if (![RolesEnum.Admin, RolesEnum.Comercial].includes(loggedInUser?.role)) {
                Client.getClient().then((client: any) => {
                    //actualizo variable de cliente habilitado
                    dispatch(setClientAvailable(client?.data.isAvailable));
                    dispatch(setClientName(client?.data.description));
                    dispatch(setClientCurrencyType(client?.data.currency || ''));
                    dispatch(setClientCounty(client?.data?.country?.id || -1));
                });
            }
        }
    }, [location]);

    /**
     * not logged in so redirect to login page with the return url
     */
    if (api.isUserAuthenticated() === false) {
        return <LoginAuth0 />;
        //return <Navigate to={'/account/login'} state={{ from: location }} replace />;
    }

    // check if route is restricted by role
    if (roles && roles.indexOf(loggedInUser.role) === -1) {
        // role not authorised so redirect to home page
        return <Navigate to={`${loggedInUser.role}/landing`} state={{ from: location }} replace />;
    }

    return <RouteComponent />;
};

export default PrivateRoute;

