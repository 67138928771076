import { useTranslation } from "react-i18next";

import { Spinner } from "components";

type LoadingSpinnerProps = {
  loadingText: string;
};

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const { t } = useTranslation();
  return (<>
    <Spinner className="spinner-border-sm" tag="span" color="white" />
    <span className="ms-1">{t(props.loadingText)}</span>
  </>)
};
LoadingSpinner.defaultProps = {
  loadingText: 'Loading...'
}

export default LoadingSpinner;
