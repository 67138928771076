import { GridHeaderCellProps } from "@progress/kendo-react-grid";
import { Component } from "react";

export default class ColumnWithTooltipHeader extends Component<GridHeaderCellProps, {}> {
    render() {
        return (
            <a className="k-link" onClick={this.props.onClick}>
                <span title={this.props.title}>{this.props.title}</span>
                {this.props.children}
            </a>
        );
    }
}