import { useRedux, useToggleNotification } from "hooks";
import { Alert, Row, Toast } from "react-bootstrap";
import { NotificationData } from "redux/notifications/constants";
import notificationsActions from "redux/notifications/actions";

const Notificator = () => {
    const { dispatch, appSelector } = useRedux();
    const [states, hideNotification] = useToggleNotification();

    const { notifications } = appSelector((state) => ({
        notifications: state.Notification.notifications,
    }));
    const dispatchSeleteNotificationAndHide = (notif: NotificationData) => {
        if (notif?.id) {
            dispatch(notificationsActions.deleteNotification(notif));
            hideNotification(notif.id);
        }
    };

    const renderNotification = (notification: NotificationData, index: number) => {
        return (<>
            {
                /* <Alert
                    variant={notification.type}
                    key={index.toString()}
                    onClose={() => { dispatchSeleteNotificationAndHide(notification) }}
                    show={states[notification.id || '']}
                    className="p2"
                    style={{ display: 'inline-flex' }}
                    dismissible
                    closeVariant='white'
                >
                    <div className="me-2">
                        {notification.type == 'success' && <>
                            <i className="dripicons-checkmark me-2" />
                            <strong className="me-auto">Finalizado:</strong>
                        </>}
                        {notification.type == 'danger' && <>
                            <i className="dripicons-wrong me-2" />
                            <strong className="me-auto">Error:</strong>
                        </>}
                    </div>
                    <div>
                        <span className="d-none">{notification.id}</span>
                        <span>{notification.message}</span>
                        {notification.body}
                    </div>
            </Alert> */
            }
            <Toast key={index.toString()} onClose={() => { dispatchSeleteNotificationAndHide(notification) }} show={states[notification.id || '']} delay={notification.delay} autohide className="p2">
                <Toast.Header className={'alert-' + notification.type}>
                    {notification.type == 'success' && <>
                        <i className="dripicons-checkmark me-2" />
                        <strong className="me-auto">Finalizado</strong>
                    </>}
                    {notification.type == 'danger' && <>
                        <i className="dripicons-wrong me-2" />
                        <strong className="me-auto">Error</strong>
                    </>}
                </Toast.Header>
                <Toast.Body>
                    <span className="d-none">{notification.id}</span>
                    <strong>{notification.message}</strong>
                    {notification.body}
                </Toast.Body>
            </Toast>
        </>)
    };

    return (
        <>
            <div className='notificator-container'>
                {notifications?.filter((x: any) => !x.containerClass).map(renderNotification)}
            </div>
            {

                notifications?.filter((x: any) => x.containerClass).map((notification: NotificationData, index: number) => {
                    return (<div className={notification.containerClass}>
                        {renderNotification(notification, index)}
                    </div>)
                })
            }
        </>
    );
};

export default Notificator;