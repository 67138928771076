import { Grid, GridProps } from '@progress/kendo-react-grid';

const CustomKendoGrid = (props: GridProps) => {
    return (
        <Grid {...props}>
            {props.children}
        </Grid>
    );
};

export default CustomKendoGrid;