import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Field, FieldProps } from "formik";
import { ItemsValuesContext } from "./ItemsValuesContext";
import { useTranslation } from 'react-i18next';

const FormSelectField = ({ as, md, controlId, label, name, type, inputGroupPrepend, disabled, children, items, valueField, displayField, placeholder, onChange }: any) => {
    const { t } = useTranslation();
    return (
        <ItemsValuesContext.Consumer>
            {({ itemsValues, setItemValue }) => (
                <Field name={name} render={({ field, form, meta }: any) => {
                    return (
                        <Form.Group as={as} md={md} controlId={controlId} className="mb-3">
                            <Form.Label>{label}</Form.Label>
                            <InputGroup>
                                {inputGroupPrepend}
                                <Form.Select
                                    {...field}
                                    type={type}
                                    value={meta.value}
                                    onChange={e => {
                                        form.setFieldValue(name, e.target.value);
                                        let item = items?.filter((x: any) => x[valueField] == e.target.value)[0];
                                        setItemValue(name, item);
                                        if (onChange) {
                                            onChange(e, e.target.value, item);
                                        }
                                    }}
                                    // isValid={meta.touched && !meta.error}
                                    isInvalid={(meta.touched || form.submitCount > 0) && meta.error}
                                    feedback={meta.error}
                                    disabled={disabled}
                                    as="select"
                                >
                                    <option value=''>{placeholder || (items?.length ? t('formSelectField.messages.select') : t('formSelectField.messages.noItemsToSelect'))}</option>
                                    {
                                        items?.map((x: any) => {
                                            return (
                                                <option key={x[valueField]} value={x[valueField]}>{x[displayField]}</option>
                                            );
                                        })
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {meta.error}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    );
                }}
                />
            )}
        </ItemsValuesContext.Consumer>
    );
};

FormSelectField.defaultProps = {
    type: "select",
    inputGroupPrepend: null
};

export default FormSelectField;
