import { Spinner } from "components";

type LoadingSpinnerMiniProps = {
  isLoading: boolean;
  children: React.ReactNode;
};

const LoadingSpinnerMini = ({ isLoading, children }: LoadingSpinnerMiniProps) => {
  return (<>
    {isLoading && (
      <Spinner className="vertical-align-middle spinner-border-sm m-2" />
    )}
    {!isLoading && (<>{children}</>)}
  </>)
};

export default LoadingSpinnerMini;
