import { RolesEnum } from "types/Roles";
import { CurrencyItem } from '../../layouts/types';

enum GlobalsActionTypes {
    SET_CLIENT_AVAILABLE = '@@GLOBALS/SET_CLIENT_AVAILABLE',
    SET_CLIENT_NAME = '@@GLOBALS/SET_CLIENT_NAME',
    SET_CLIENT_CURRENCY_TYPE = '@@GLOBALS/SET_CLIENT_CURRENCY_TYPE',
    SET_USER_ROLE = '@@GLOBALS/SET_USER_ROLE',
    SET_CLIENT_COUNTRY = '@@GLOBALS/SET_CLIENT_COUNTRY',
    SET_AVAILABLE_CURRENCIES = '@@GLOBALS/SET_AVAILABLE_CURRENCIES'
}

export type GlobalstateType = {
    clientAvailable:boolean,
    clientName:string,
    clientCurrencyType:string,
    userRole:RolesEnum,
    clientCountryId:number,
    availableCurrencies:CurrencyItem[]
};

export { GlobalsActionTypes };
