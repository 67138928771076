export enum InstrumentTypesEnum {    
    MF = "MF",
    CD = "CD",
    CS = "CS",
    CORP = "CORP",
    GO = "GO",
    STN = "STN",
    FUT = "FUT",
    OPT = "OPT",
    OOF = "OOF",
    XLINKD = "XLINKD",
    T = "T",
    REPO = "REPO",
    BUYSELL = "BUYSELL",
    TERMT = "TERMT",
    SPOT = "SPOT"
};