import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEs from 'locales/es-ar/translation.json';
import translationEn from 'locales/en-us/translation.json';
import translationGer from 'locales/de/translation.json';
import translationIta from 'locales/it/translation.json';
import translationRus from 'locales/ru/translation.json';

// translations
const resources = {
    es: {
        translation: translationEs,
    },
    en: {
        translation: translationEn,
    },
    de: {
        translation: translationGer,
    },
    it: {
        translation: translationIta,
    },
    ru: {
        translation: translationRus,
    },
};

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'es',
        fallbackLng: 'es', // use en if detected lng is not available

        keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
