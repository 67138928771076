export type UserAuth0 = {
    id: number;
    idauth0: string;
    email?: string;
    username: string;
    password: string;
    name?: string;
    token?: string;
    codCliente?: number;
    role?: string;
    identities?: any;
    profile?: Profile;
    datalogins?: Login;
};

export type Profile = {
    email_verified?: boolean;
    picture?: string;
    language?: number;
    notifications?: boolean;
    clientAvailable?: boolean;
    clientName?: string;
    isClientBase?: boolean;
};

export type Login = {
    last_ip?: string;
    created_at?: Date;
    updated_at?: Date;
    last_login?: Date;
    logins_count: number;
    last_password_reset: Date;
};

const saveUser = (user: any, token: string, data?: any) => {
    let _user: UserAuth0 = {
        id: data?.user_metadata.id,
        idauth0: user?.sub || '',
        email: user?.email || '',
        username: user?.email || '',
        password: user?.email || '',
        name: data?.user_metadata.name,
        token: token,
        codCliente: data?.user_metadata.client,
        role: data?.user_metadata.roles[0] || 'User',
        identities: data?.identities,
        profile: {
            email_verified: data?.email_verified,
            picture: data?.user_metadata.picture || data?.picture,
            language: data?.user_metadata.language,
            notifications: data?.user_metadata.notifications,
            clientAvailable: data?.user_metadata.clientAvailable,
            clientName: data?.user_metadata.clientName,
            isClientBase: data?.user_metadata.clientBase || false
        },
        datalogins: {
            last_ip: data?.last_ip,
            created_at: data?.created_at,
            updated_at: data?.updated_at,
            last_login: data?.last_login,
            last_password_reset: data?.last_password_reset,
            logins_count: data?.logins_count || 0
        }
    }
    return _user;
};

export { saveUser };
