import { Form } from "react-bootstrap";
import { Field } from "formik";

const FormBooleanField = ({ controlId, label, name, containerClass, onChange, helpIcon, disabled }: any) => {
    return (
        <Field
            name={name}
            render={({ field, form, meta }: any) => {
                if (!form.values.default && form.values[name] === undefined && form.initialValues.default) {
                    form.setFieldValue(name, form.initialValues.default[name]);
                }

                return (
                    <Form.Group controlId={controlId} className={containerClass}>
                        <Form.Check
                            type="checkbox"
                            {...field}
                            name={name}
                            label={(<>{label} {helpIcon}</>)}
                            checked={meta.value}
                            disabled={disabled}
                            onChange={e => {
                                form.setFieldValue(name, e.target.checked);
                                if (onChange) {
                                    onChange(e);
                                }
                            }}
                        />
                    </Form.Group>
                );
            }}
        />
    );
};

FormBooleanField.defaultProps = {
};

export default FormBooleanField;
