import { NumberFormatOptions } from "@progress/kendo-react-intl";
import { einvMomentDateFormat, kendoCurrencyDefaultFormat } from "appConstants";
import useFormatterService from "hooks/useFormatterService";
import moment from "moment";
import { InstrumentTypesEnum } from "types/InstrumentTypesEnum";

const useResolveInstrumentDisplayLabel = () => {
    const { currencyFormat, quantityFormat } = useFormatterService();

    const getDisplayLabel = (option: any) => {
        const instrumentType = option?.instrumentsType?.type;
        switch (instrumentType) {
            case InstrumentTypesEnum.SPOT:
                return option?.symbol || '';
            default:
                return option?.denomination || '';
        }
    };

    const getNumberValue = (value: number | undefined) => {
        return quantityFormat(value) || '-';
    };

    const getCurrencyValue = (value: number | undefined, format: NumberFormatOptions | undefined = kendoCurrencyDefaultFormat) => {
        const defaultValue = '-';
        if (value == undefined) {
            return defaultValue;
        }
        return currencyFormat(value, format) || defaultValue;
    };

    const getPercentValue = (value: number | undefined, format: NumberFormatOptions | undefined = kendoCurrencyDefaultFormat) => {
        const defaultValue = '-';
        let formatted = quantityFormat(value, format);
        return formatted !== undefined && formatted !== null && formatted !== '' ? `${formatted}%` : defaultValue;
    };
    const getDesc = (values: any, itemsValues: any, key: string, displayField: string = 'description') => {
        if (!values[key]) {
            return '-';
        }
        let item = itemsValues[key];
        return item ? item[displayField] || '-' : '-';
    };
    const getDateDesc = (values: any, key: string) => {
        let value = moment(values[key]);
        return value.format(einvMomentDateFormat);
    };


    return { getDisplayLabel, getNumberValue, getDesc, getDateDesc, getCurrencyValue, getPercentValue };
};

export default useResolveInstrumentDisplayLabel;
