import useRedux from './useRedux';
import { addNotification } from 'redux/notifications/actions';
import { useTranslation } from 'react-i18next';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

import { ApiError } from 'types/Error';
import { NotificationData } from 'redux/notifications/constants';

export default function useNotifications() {
    const { dispatch, appSelector } = useRedux();
    const { t } = useTranslation();

    const success = (message: string, options: NotificationData = {}) => {
        dispatch(addNotification({ message: message, type: 'success', ...options }));
    };

    const info = (message: string, options: NotificationData = {}) => {
        dispatch(addNotification({ message: message, type: 'info', ...options }));
    };

    const warning = (message: string, options: NotificationData = {}) => {
        dispatch(addNotification({ message: message, type: 'warning', ...options }));
    };

    function showSwalDialog(options: SweetAlertOptions<any>): Promise<SweetAlertResult<Awaited<any>>> {
        let defaultOptions: SweetAlertOptions<any> = {
            title: t('Atención'),
            showCancelButton: true,
            allowOutsideClick: false, //No se cierra cuando se hace click por fuera
            reverseButtons: true, //Botón Si o Aceptar se muestra a la derecha,
            cancelButtonText: t('No'),
            confirmButtonText: t('Si'),
            customClass: {
                popup: 'custom-backgroud-color',
                title: 'page-title text-dark',
                htmlContainer: 'text-dark'
            }

        };
        return Swal.fire({
            ...defaultOptions,
            ...options
        });
    };

    const error = (err: any) => {
        let message: string = t('Ha ocurrido un error');
        if (err) {
            message = err as string;
            if (err instanceof Object) {
                let apiError = err as ApiError;
                if (apiError) {
                    let errors: any[] = [];
                    for (const key in apiError.errors) {
                        if (Object.prototype.hasOwnProperty.call(apiError.errors, key)) {
                            const element = apiError.errors[key];
                            errors.push(<>
                                <li>{key}</li>
                                <ul>{element.map(x => <li>{x}</li>)}</ul>
                            </>)
                        }
                    }

                    dispatch(addNotification({
                        type: 'danger',
                        body: (<>
                            <strong>{apiError.title}</strong>
                            <ul>
                                {errors.map(x => <li>{x}</li>)}
                            </ul>
                        </>),
                    }));
                }
            }
        }
        dispatch(addNotification({ message: message, type: 'danger' }));
    };

    return {
        success, 
        error, 
        info, 
        warning, 
        showSwalDialog
    };
}