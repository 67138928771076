import { useEffect } from 'react';
import { useGoogleAnalyticsEvents, useRedux } from 'hooks';
import { useAuth0 } from "@auth0/auth0-react";
import { loginUser } from 'redux/actions';
import { APICore, setAuthorization } from 'helpers/api/apiCore';
import { useApiCalls } from 'helpers/api/useApiCalls';
import { AuthActionTypes } from '../../redux/auth/constants';
import { authApiResponseError, authApiResponseSuccess } from '../../redux/auth/actions';
import { put } from 'redux-saga/effects';
import Loading from "../../components/Loading";
import { UserAuth0, saveUser } from 'helpers/api/saveUser';
import { GoogleAnalyticsEventEnum } from 'types/GoogleAnalyticsEventType';

const LoginAuth0 = () => {
  const { loginWithRedirect, isAuthenticated, getAccessTokenSilently, logout, user } = useAuth0();
  const { dispatch } = useRedux();
  const { Auth0 } = useApiCalls();
  const { trackEvent } = useGoogleAnalyticsEvents();
  const api = new APICore();

  useEffect(() => {
    if (isAuthenticated) {

      const saveData = (token: string) => {
        Auth0.get()
          .then((response: { data: any }) => {
            trackEvent({
              category: GoogleAnalyticsEventEnum.AUTH0_LOGIN_SUCCESSFUL,
              label: `user: ${JSON.stringify(user)}`
            })
            let data: UserAuth0 = saveUser(user, token, response.data);
            api.setLoggedInUser(data);
            dispatch(loginUser(data.username, data.password));
            put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, data));

            if (data.datalogins?.logins_count == 1) {
              trackEvent({
                category: GoogleAnalyticsEventEnum.AUTH0_FIRST_LOGIN,
                label: `user: ${JSON.stringify(user)}`
              });
              Auth0.firstlogin();
            }
          })
          .catch((error: any) => {
            trackEvent({
              category: GoogleAnalyticsEventEnum.AUTH0_LOGIN_FAILED ,
              label: `error: ${error}`
            });
            console.log(error)
            put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
            api.setLoggedInUser(null);
            setAuthorization(null);
            logout({ returnTo: window.location.origin });
          });
      };

      getAccessTokenSilently()
        .then((token) => {
          setAuthorization(token);
          saveData(token);
        })
    } else {
      const url = window.location.href;
      const inviteMatches = url.match(/invitation=([^&]+)/);
      const orgMatches = url.match(/organization=([^&]+)/);
      if (inviteMatches && orgMatches) {
        loginWithRedirect({
          organization: orgMatches[1],
          invitation: inviteMatches[1],
        });
      } else {
        loginWithRedirect();
      }
    }
  }, [isAuthenticated, user])

  return <Loading />;
};

export default LoginAuth0;