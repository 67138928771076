import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Modals from './modals/reducers';
import Notification from './notifications/reducers';
import UserPreferences from './userPreferences/reducers';
import Global from './global/reducers';
import MovimientosFilter from './movimientosFilters/reducers';
import RendimientosFilter from './movimientosFilters/reducers';

export default combineReducers({
    Auth,
    Layout,
    Modals,
    Notification,
    UserPreferences,
    Global,
    MovimientosFilter,
    RendimientosFilter,
});
