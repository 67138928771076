export enum GoogleAnalyticsEventEnum {
    AUTH0_LOGIN_SUCCESSFUL = '@@auth/AUTH0_LOGIN_SUCCESSFUL',
    AUTH0_FIRST_LOGIN = '@@auth/AUTH0_FIRST_LOGIN',
    AUTH0_LOGIN_FAILED = '@@auth/AUTH0_LOGIN_FAILED ',
}

export type GoogleAnalyticsEventType = {
    category?: GoogleAnalyticsEventEnum;
    action?: string;
    label?: string;
}
