import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

type HelpPopoverIconProps = {
  title?: React.ReactNode;
  message?: React.ReactNode;
};

const HelpPopoverIcon = ({ title, message }: HelpPopoverIconProps) => {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={
        <Popover id="popover-basic">
          <Popover.Header as="h3">{title}</Popover.Header>
          <Popover.Body>{message}</Popover.Body>
        </Popover>
      }
    >
        <i className='mdi mdi-help-circle-outline'></i>
    </OverlayTrigger>
  )
};

export default HelpPopoverIcon;
