import React, { useEffect, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

type EIFiltrosListaProps = {
    label?: string;
    options: Array<any>;
    optionKey: number | string;
    optionLabel: string;
    optionDisabledKey?: string;
    selectedValues?: any;
    disabled?: boolean;
    onChange?: (selectedValues: any) => void;
    seleccionarTodosCfg?: {
        defaultSelected: boolean;
        showSelectAllCheck?: boolean;
        selectAllCheckLabel?: string;
    };
};

const EIFiltrosLista = (props: EIFiltrosListaProps) => {
    const [allPortfoliosSelected, setAllPortfoliosSelected] = useState<boolean>(props.seleccionarTodosCfg?.defaultSelected || false);
    const [portfolioIdList, setPortfolioIdList] = useState<any>(props.selectedValues || {});
    const [items, setItems] = useState<Array<any>>([]);
    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
        setItems(props.options);
    }, [props.options]);

    useEffect(() => {
        onChange();
    }, [portfolioIdList]);

    useEffect(() => {
        if (props.disabled) {
            setDisabled(!!props.disabled);
        }
    }, [props.disabled]);

    const onChange = () => {
        if (props.onChange) {
            let selectedList: any[] | undefined = undefined;
            if (portfolioIdList) {
                for (const carteraId in portfolioIdList) {
                    if (Object.prototype.hasOwnProperty.call(portfolioIdList, carteraId)) {
                        const selected = portfolioIdList[carteraId];
                        if (selected == true || selected == undefined) {
                            selectedList = selectedList || [];
                            selectedList.push(+carteraId || carteraId);
                        }
                    }
                }
            }
            props.onChange(selectedList);
        }
    };

    const auxGetIsDisabled = (item: any) => {
        if (disabled) {
            return disabled;
        }
        if (props.seleccionarTodosCfg?.showSelectAllCheck) {
            return allPortfoliosSelected;
        }

        if (props.optionDisabledKey)
            return !item[props.optionDisabledKey];
    };

    return (
        <>
            {props.seleccionarTodosCfg?.showSelectAllCheck && (
                <Form.Group>
                    <Form.Check type="switch" key='allPortfoliosSelected'
                        id={`switch-allPortfoliosSelected`}
                        label={props.seleccionarTodosCfg.selectAllCheckLabel}
                        checked={allPortfoliosSelected}
                        disabled={disabled}
                        onChange={(e: any) => {
                            let newValue = !allPortfoliosSelected;
                            setAllPortfoliosSelected(newValue);
                        }}
                    />
                </Form.Group>
            )}
            {props.label && (
                <p className="mt-3">
                    {props.label}
                </p>
            )}
            <div style={{ maxHeight: 380, overflow: 'auto' }}>
                {
                    items?.map((item: any, i: number) => {
                        let selected = false;
                        if (portfolioIdList)
                            selected = portfolioIdList[item[props.optionKey]] || portfolioIdList[item[props.optionKey]] == undefined;
                        else if (allPortfoliosSelected)
                            selected = true;

                        let isDisabled = auxGetIsDisabled(item);

                        return (
                            <Form.Check type="switch" key={i.toString()}
                                id={`switch-${item[props.optionKey]}`}
                                label={item[props.optionLabel]}
                                disabled={isDisabled}
                                checked={selected}
                                onChange={(e: any) => {
                                    setPortfolioIdList({
                                        ...portfolioIdList,
                                        [item[props.optionKey]]: e.target.checked
                                    });
                                }}
                            />
                        );
                    })
                }
            </div>
        </>
    );
};

export default EIFiltrosLista;
