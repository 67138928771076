import { ReactNode } from "react";
import { LinkProps } from "react-router-dom";

interface LeftAndRigthRowProps {
  left?: ReactNode | undefined;
  right?: ReactNode | undefined;
};

const LeftAndRigthRow = (props: LeftAndRigthRowProps) => {
  return (

    <ul className="list-inline mb-0" style={{ display: 'block', width: '100%' }}>
      <li className="previous list-inline-item">
        {props.left}
      </li>
      <li className="next list-inline-item float-end">
        {props.right}
      </li>
    </ul>
  );
};

export default LeftAndRigthRow;
