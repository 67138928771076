
import { IntlProvider, LocalizationProvider, load } from '@progress/kendo-react-intl';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import localCurrency from "cldr-numbers-full/main/es-AR/currencies.json";
import numbers from "cldr-numbers-full/main/es-AR/numbers.json";
import { einv_locale } from 'appConstants';

//kendo load 
load(likelySubtags, currencyData, localCurrency, numbers);

const EINV_IntlProvider = (props: any) => {
  return (
      <LocalizationProvider language={einv_locale}>
          <IntlProvider locale={einv_locale}>
              {props.children}
          </IntlProvider>
      </LocalizationProvider>
  );
}

export default EINV_IntlProvider;