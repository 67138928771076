import Spinner from "./Spinner";

type LoadingPortletProps = {
  isLoading: boolean;
  children: React.ReactNode;
  className?: string
};

const LoadingPortlet = ({ isLoading, children, className }: LoadingPortletProps) => {
  return (<>
    {isLoading && (
      <div className={className}>
        {/* <p>Cargando...</p>
        <div className="card-disabled">
          <div className="card-portlets-loader"></div>
        </div> */}
        <br/>
        <br/>
        <br/>
        <br/>
        <div>
          <div className="einv-loading-container">
            <div className="einv-loading-spinner mt-1 mb-1">
              <Spinner className="m-2" color='primary' />
              <p>Cargando...</p>
            </div>
          </div>
        </div>
      </div>
    )}
    {!isLoading && !className && (<>{children}</>)}
    {!isLoading && className && (
      <div className={className}>
        {children}
      </div>
    )}
  </>)
};

export default LoadingPortlet;
