import { MouseEventHandler, useEffect, useState } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import LoadingSpinner from "./LoadingSpinner";

interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean;
  loadingText: string;
};

const LoadingButton = (props: LoadingButtonProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(!!props.isLoading);

  useEffect(() => { setIsLoading(props.isLoading)}, [props.isLoading]);

  return (
    <Button {...props} disabled={props.disabled || isLoading}>
      {isLoading == false && <> {props.children}</>}
      {isLoading == true && <LoadingSpinner loadingText={props.loadingText} />}
    </Button>
  );
};

LoadingButton.defaultProps = {
  isLoading: false,
  loadingText: 'Loading...'
}

export default LoadingButton;
