import { RolesEnum } from "types/Roles";
import { GlobalActionType } from "./actions";
import { GlobalstateType, GlobalsActionTypes } from "./constants";

const INIT_STATE: GlobalstateType = {
    clientAvailable: true,
    clientName: '',
    clientCurrencyType: '',
    clientCountryId: -1,
    userRole: RolesEnum.Users,
    availableCurrencies: []
};

const Globals = (state: GlobalstateType = INIT_STATE, action: GlobalActionType<boolean>) => {
    switch (action.type) {
        case GlobalsActionTypes.SET_CLIENT_AVAILABLE:
            return {
                ...state,
                clientAvailable: action.payload,
            };
        case GlobalsActionTypes.SET_CLIENT_NAME:
            return {
                ...state,
                clientName: action.payload,
            };
        case GlobalsActionTypes.SET_USER_ROLE:
            return {
                ...state,
                userRole: action.payload,
            };
        case GlobalsActionTypes.SET_CLIENT_CURRENCY_TYPE:
            return {
                ...state,
                clientCurrencyType: action.payload,
            };
        case GlobalsActionTypes.SET_CLIENT_COUNTRY:
            return {
                ...state,
                clientCountryId: action.payload,
            };
        case GlobalsActionTypes.SET_AVAILABLE_CURRENCIES:
            return {
                ...state,
                availableCurrencies: action.payload,
            };            
        default:
            return state;
    }
};

export default Globals;
