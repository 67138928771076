import { Auth0Provider } from "@auth0/auth0-react";
import history from "../helpers/history";
import config from "../config";

const EINV_AuthProvider = (props: any) => {

  //Auth0 provider config
  const onRedirectCallback = (appState: any) => {
    history.push(
      appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
  };

  const providerConfig = {
    domain: config.REACT_APP_AUTH0_DOMAIN,
    clientId: config.REACT_APP_AUTH0_CLIENT,
    ...(config.REACT_APP_AUTH0_API ? { audience: config.REACT_APP_AUTH0_API } : null),
    redirectUri: window.location.origin,
    ...(config.REACT_APP_AUTH0_ORG ? { organization: config.REACT_APP_AUTH0_ORG } : null),
    onRedirectCallback,
  };

  return (
    <Auth0Provider {...providerConfig}>
      {props.children}
    </Auth0Provider>
  )
}

export default EINV_AuthProvider;