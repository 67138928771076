import Notificator from 'layouts/Notificator/Notificator';
import { Row, Col } from 'react-bootstrap';

type EIPageTitlePageTitleProps = {
    buttonsAndFilters?: React.ReactNode;
    title?: React.ReactNode;
    className?: string;
};

const EIPageTitle = ({ title, buttonsAndFilters, className }: EIPageTitlePageTitleProps) => {
    return (
        <>
            <Row className={className}>
                <Col>
                    <div className="d-flex h-75-p">
                        <div className="flex-grow-1 align-self-center">
                            <h3 className="page-title text-dark">
                                {title}
                            </h3>
                        </div>
                        <div className="align-self-center">{buttonsAndFilters}</div>
                    </div>
                </Col>
            </Row>
            {/* <Notificator /> */}
        </>
    );
};

export default EIPageTitle;
