import moment from "moment";
import { Cotizaciones_ResponseConsulta } from "types/Cotizaciones_ResponseConsulta";
import { LineChartInfoType } from "types/LineChartInfoType";
import { PieChartInfoType } from "types/PieChartInfoType";
import { PortfolioDataType } from "types/Portfolio";
import { PortFolioDetailType } from "types/PortFolioDetailType";
import { PortFolioGroupsType } from "types/PortFolioGroupsType";
import { PortFolioResumeType } from "types/PortFolioResumeType";
import { carteraReservaData } from "./data/carteraReservaData";
import { carteraSegurosData } from "./data/carteraSegurosData";
import { cotizacionesGetData } from "./data/cotizacionesGetData";
import { einvMomentDateFormat } from "appConstants";

const carterasNames: string[] = ['Cartera 1 (dólares)', 'Cartera 2 (dólares)', 'Cartera 3 (pesos)', 'Cartera 4 (pesos)', 'Cartera 5 (pesos)'];
const activosNames: string[] = ['Apple', 'Disney', 'Nasdaq', 'BTC-MTR', 'BTC'];
const tiposDeActivoNames: string[] = ['Monedas', 'Futuros', 'Acciones', 'Bonos', 'Opciones'];
function generateRandomPieData(nameArray: string[]): PieChartInfoType[] {
    let pieData: PieChartInfoType[] = [];
    for (let i = 0; i < nameArray.length; i++) {
        pieData.push({
            name: nameArray[i],
            value: Math.random() * 100
        });
    }
    return pieData;
};

const records: PortFolioDetailType[] = [
    {
        id: '1',
        name: "Seguros",
        evolution: carteraSegurosData,
        valuacionTotal: 14595.872551201694,
        resultadoPeriodo: 24195.872551201694,
        resultadoDiario: 15,
        composicionByTpActivo: generateRandomPieData(tiposDeActivoNames),
        composicionByActivo: generateRandomPieData(activosNames)
    },
    {
        id: '2',
        name: "Reserva",
        evolution: carteraReservaData,
        valuacionTotal: 47020.62844316446,
        resultadoPeriodo: 35095.872551201694,
        resultadoDiario: 4,
        composicionByTpActivo: generateRandomPieData(tiposDeActivoNames),
        composicionByActivo: generateRandomPieData(activosNames)
    },
];

const portfolioGroups: PortFolioGroupsType[] = [
    {
        id: 1,
        description: "Grupo USD",
        client: {
            id: 3,
            description: "prueba"
        },
        portfolios: [
            {
                id: 22,
                description: "Cartera USD",
                isAvailable: true
            },
            {
                id: 23,
                description: "Cartera USD ++ Plus",
                isAvailable: true
            },
            {
                id: 2,
                description: "Prueba Pablo",
                isAvailable: true
            },
            {
                id: 39,
                description: "Nueva cartera 987",
                isAvailable: true
            },
            {
                id: 39,
                description: "Nueva cartera 987",
                isAvailable: true
            },
            {
                id: 1,
                description: "Hola Mundo",
                isAvailable: true
            },
            {
                id: 18,
                description: "Prueba Jona123521449288 mod",
                isAvailable: true
            },
            {
                id: 40,
                description: "1",
                isAvailable: true
            },
            {
                id: 40,
                description: "1",
                isAvailable: true
            },
            {
                id: 42,
                description: "20025 PORTFOLIO 2",
                isAvailable: true
            },
            {
                id: 13,
                description: "pppp3",
                isAvailable: true
            },
            {
                id: 47,
                description: "Test David",
                isAvailable: true
            },
            {
                id: 47,
                description: "Test David",
                isAvailable: true
            }
        ],
        isAvailable: false
    },
    {
        id: 2,
        description: "Grupo ARS",
        client: {
            id: 4,
            description: "prueba2"
        },
        portfolios: [
            {
                id: 2,
                description: "Prueba Pablo",
                isAvailable: true
            }
        ],
        isAvailable: false
    },
    {
        id: 3,
        description: "20025 GRUPO 1 modificado",
        client: {
            id: 7,
            description: "CLIENTE 20025  Modificado"
        },
        portfolios: [
            {
                id: 41,
                description: "20025 PORTFOLIO 1",
                isAvailable: true
            }
        ],
        isAvailable: false
    },
    {
        id: 4,
        description: "20025 GRUPO 2",
        client: {
            id: 7,
            description: "CLIENTE 20025  Modificado"
        },
        portfolios: [],
        isAvailable: false
    },
    {
        id: 5,
        description: "20025 GRUPO 3",
        client: {
            id: 7,
            description: "CLIENTE 20025  Modificado"
        },
        portfolios: [],
        isAvailable: false
    },
    {
        id: 6,
        description: "20025 GRUPO 4",
        client: {
            id: 7,
            description: "CLIENTE 20025  Modificado"
        },
        portfolios: [],
        isAvailable: false
    },
    {
        id: 7,
        description: "GRUPO QA",
        client: {
            id: 3,
            description: "prueba"
        },
        portfolios: [
            {
                id: 48,
                description: "Portofolio con grupo",
                isAvailable: true
            },
            {
                id: 49,
                description: "Portofolio con grupo 1",
                isAvailable: true
            }
        ],
        isAvailable: false
    },
    {
        id: 8,
        description: "Grupo Cliente 8",
        client: {
            id: 8,
            description: "CLIENTE 20030"
        },
        portfolios: [],
        isAvailable: false
    }
];

const getPortfolioDetail2 = (carteraId: string | string[] | null, dateFrom: string | null, dateTo: string | null): PortFolioDetailType[] => {
    let retorno: PortFolioDetailType[] = [];
    let _records = records;

    //Filtro por nombre de cartera
    if (carteraId !== undefined && carteraId) {
        if (typeof carteraId === "string") {
            _records = records.filter(x => x.id == carteraId);
        }
        else {
            _records = records.filter(x => carteraId.includes(x.id || ''));
        }
    }
    let start = moment(dateFrom);
    let end = moment(dateTo);
    if (start.isValid() && end.isValid()) {
        //Filtro por fecha desde y hasta
        _records.forEach((cartera: PortFolioDetailType) => {
            retorno.push({
                id: cartera.id,
                name: cartera.name,
                composicionByActivo: cartera.composicionByActivo,
                composicionByTpActivo: cartera.composicionByTpActivo,
                valuacionTotal: cartera.valuacionTotal,
                resultadoPeriodo: cartera.resultadoPeriodo,
                resultadoDiario: cartera.resultadoDiario,
                evolution: (cartera.evolution as { date: string, value: number }[]).filter((x: LineChartInfoType) => {
                    let _moment = moment(x.date);
                    return _moment >= start && _moment <= end;
                }),
            });
        });
    }
    else {
        _records.forEach((cartera: PortFolioDetailType) => {
            retorno.push({
                id: cartera.id,
                name: cartera.name,
                composicionByActivo: cartera.composicionByActivo,
                composicionByTpActivo: cartera.composicionByTpActivo,
                evolution: cartera.evolution as { date: string, value: number }[]
            });
        });
    }

    return retorno;
};
const randomIntFromInterval = (min: number, max: number) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
};

const getPortfolioDetail = (portfolios: PortfolioDataType[] | undefined, dateFrom: string | null, dateTo: string | null): PortFolioDetailType[] => {
    let retorno: PortFolioDetailType[] = [];
    let _records: PortFolioDetailType[] = [];

    //Filtro por nombre de cartera
    if (portfolios !== undefined && portfolios.length) {
        if (portfolios.length == 1) {
            //un portfolio
            let actual = portfolios[0];
            if (actual.isAvailable) {
                let random = randomIntFromInterval(0, 1);
                _records.push({ ...records[random], id: actual.id.toString(), name: actual.description });
            }
        }
        else {
            //muchos portfolios
            _records = portfolios.filter(x => x.isAvailable).map(p => {
                let random = randomIntFromInterval(0, 1);
                let newItem: PortFolioDetailType = { ...records[random], id: p.id.toString(), name: p.description };
                return newItem;
            })
        }
    }
    else {
        let random = randomIntFromInterval(0, 1);
        let actual = records[0];
        _records.push({ ...records[random], id: actual?.id?.toString(), name: actual?.name });
    }
    let start = moment(dateFrom);
    let end = moment(dateTo);
    if (start.isValid() && end.isValid()) {
        //Filtro por fecha desde y hasta
        _records.forEach((cartera: PortFolioDetailType) => {
            retorno.push({
                id: cartera.id,
                name: cartera.name,
                composicionByActivo: cartera.composicionByActivo,
                composicionByTpActivo: cartera.composicionByTpActivo,
                valuacionTotal: cartera.valuacionTotal,
                resultadoPeriodo: cartera.resultadoPeriodo,
                resultadoDiario: cartera.resultadoDiario,
                evolution: (cartera.evolution as { date: string, value: number }[])?.filter((x: LineChartInfoType) => {
                    let _moment = moment(x.date);
                    return _moment >= start && _moment <= end;
                }) || [],
            });
        });
    }
    else {
        _records.forEach((cartera: PortFolioDetailType) => {
            retorno.push({
                id: cartera.id,
                name: cartera.name,
                composicionByActivo: cartera.composicionByActivo,
                composicionByTpActivo: cartera.composicionByTpActivo,
                evolution: cartera.evolution as { date: string, value: number }[]
            });
        });
    }

    return retorno;
};
const getPortfolioResume = (dateFrom: string | null, dateTo: string | null, portfolios: PortfolioDataType[] | undefined): PortFolioResumeType | null | undefined => {
    let start = moment(dateFrom);
    let end = moment(dateTo);
    if (!start.isValid() || !end.isValid()) {
        return null;
    }

    var details = getPortfolioDetail(portfolios, dateFrom, dateTo);
    let results: PortFolioResumeType = {
        sumarized: {
            id: '999',
            name: 'Totales',
            evolution: [],
            valuacionTotal: 0,
            composicionByCartera: generateRandomPieData(carterasNames),
            composicionByTpActivo: generateRandomPieData(tiposDeActivoNames)
        },
        detail: details
    };
    let aux: any = {};
    let valuacionTotal: number = 0;
    details.forEach(information => {
        information?.evolution?.forEach((detail: LineChartInfoType) => {
            let key = moment(detail.date).format(einvMomentDateFormat);
            if (aux[key] !== undefined) {
                aux[key].value += detail.value;
            }
            else {
                aux[key] = { date: detail.date, value: detail.value };
            }
        });
        valuacionTotal += information?.valuacionTotal || 0;
    });
    for (const key in aux) {
        if (Object.prototype.hasOwnProperty.call(aux, key)) {
            const result = aux[key];
            results.sumarized?.evolution?.push({
                date: result.date,
                value: result.value
            });
        }
    }
    if (results && results.sumarized) {
        results.sumarized.valuacionTotal = valuacionTotal;
    }

    return results;
};

const getPortfolioResume2 = (dateFrom: string, dateTo: string, portfolioIds: string | string[] | null): PortFolioResumeType | null | undefined => {
    let start = moment(dateFrom);
    let end = moment(dateTo);
    if (!start.isValid() || !end.isValid()) {
        return null;
    }

    var details = getPortfolioDetail2(portfolioIds, dateFrom, dateTo);
    let results: PortFolioResumeType = {
        sumarized: {
            id: '999',
            name: 'Totales',
            evolution: [],
            valuacionTotal: 0,
            composicionByCartera: generateRandomPieData(carterasNames),
            composicionByTpActivo: generateRandomPieData(tiposDeActivoNames)
        },
        detail: details
    };
    let aux: any = {};
    let valuacionTotal: number = 0;
    details.forEach(information => {
        information?.evolution?.forEach((detail: LineChartInfoType) => {
            let key = moment(detail.date).format(einvMomentDateFormat);
            if (aux[key] !== undefined) {
                aux[key].value += detail.value;
            }
            else {
                aux[key] = { date: detail.date, value: detail.value };
            }
        });
        valuacionTotal += information?.valuacionTotal || 0;
    });
    for (const key in aux) {
        if (Object.prototype.hasOwnProperty.call(aux, key)) {
            const result = aux[key];
            results.sumarized?.evolution?.push({
                date: result.date,
                value: result.value
            });
        }
    }
    if (results && results.sumarized) {
        results.sumarized.valuacionTotal = valuacionTotal;
    }

    return results;
};

const getPortfolioGroups = (id: string): PortFolioGroupsType[] => {
    let retorno: PortFolioGroupsType[];
    if (id) {
        retorno = portfolioGroups.filter(x => x.id?.toString() == id);
    }
    else {
        retorno = portfolioGroups;
    }
    return retorno;
};

const getPortfolioData = (): any[] => {
    return [
        {
            id: 1,
            description: "Hola Mundo",
            client: {
                id: 3,
                description: "prueba"
            },
            isAvailable: true
        },
        {
            id: 2,
            description: "Prueba Pablo",
            client: {
                id: 3,
                description: "prueba"
            },
            isAvailable: true
        },
        {
            id: 8,
            description: "string",
            client: {
                id: 5,
                description: "Prueba5"
            },
            isAvailable: true
        },
        {
            id: 9,
            description: "Prueba QA",
            client: {
                id: 3,
                description: "prueba"
            },
            isAvailable: true
        },
        {
            id: 10,
            description: "Prueba Yanina",
            client: {
                id: 4,
                description: "prueba2"
            },
            isAvailable: true
        },
        {
            id: 11,
            description: "pppp",
            client: {
                id: 3,
                description: "prueba"
            },
            isAvailable: true
        },
        {
            id: 12,
            description: "pppp2",
            client: {
                id: 3,
                description: "prueba"
            },
            isAvailable: true
        },
        {
            id: 13,
            description: "pppp3",
            client: {
                id: 3,
                description: "prueba"
            },
            isAvailable: true
        },
        {
            id: 14,
            description: "Prueba Yanina 2",
            client: {
                id: 6,
                description: "otra prueba"
            },
            isAvailable: true
        },
        {
            id: 15,
            description: "PRUEBA QA 2",
            client: {
                id: 4,
                description: "prueba2"
            },
            isAvailable: true
        },
        {
            id: 18,
            description: "Prueba Jona123521449288",
            client: {
                id: 4,
                description: "prueba2"
            },
            isAvailable: true
        },
        {
            id: 19,
            description: "Grupo USD",
            client: {
                id: 3,
                description: "prueba"
            },
            isAvailable: true
        },
        {
            id: 20,
            description: "Prueba Jona154625740483",
            client: {
                id: 4,
                description: "prueba2"
            },
            isAvailable: true
        },
        {
            id: 21,
            description: "Prueba Jona154928677546",
            client: {
                id: 4,
                description: "prueba2"
            },
            isAvailable: true
        },
        {
            id: 22,
            description: "Cartera USD",
            client: {
                id: 3,
                description: "prueba"
            },
            isAvailable: true
        },
        {
            id: 23,
            description: "Cartera USD ++ Plus",
            client: {
                id: 4,
                description: "prueba2"
            },
            isAvailable: true
        }
    ];
};

const getEvolucion = (carteraId: string | string[] | null, dateFrom: string | null, dateTo: string | null): LineChartInfoType[] => {
    let retorno: LineChartInfoType[] = [];
    let cartera: PortFolioDetailType = {};

    //Filtro por nombre de cartera
    if (carteraId === undefined || !carteraId) {
        return retorno;
    }

    //Busco info de cartera
    cartera = records.filter(x => x.id == carteraId)[0] || records[0];

    let start = moment(dateFrom);
    let end = moment(dateTo);

    if (start.isValid() && end.isValid()) {
        //Filtro por fecha desde y hasta
        retorno = (cartera.evolution as { date: string, value: number }[]).filter((x: LineChartInfoType) => {
            let _moment = moment(x.date);
            return _moment >= start && _moment <= end;
        });
    }
    else {
        retorno = cartera.evolution as { date: string, value: number }[];
    }

    return retorno;
};

const getEvolucionGroup = (groupId: string | string[] | null, dateFrom: string | null, dateTo: string | null): LineChartInfoType[] => {
    let resume = getPortfolioResume(dateFrom, dateTo, undefined);
    return resume?.sumarized?.evolution || [];
};

const getComposicion = (carteraId: string | string[] | null, dateFrom: string | null, dateTo: string | null): LineChartInfoType[] => {
    return generateRandomPieData(tiposDeActivoNames);
};

const getComposicionGroup = (groupId: string | string[] | null, dateFrom: string | null, dateTo: string | null): LineChartInfoType[] => {
    return generateRandomPieData(tiposDeActivoNames)
};

const getCotizacionesData = (): Cotizaciones_ResponseConsulta[] => {
    return cotizacionesGetData;
};

export { getPortfolioDetail, getPortfolioResume, getPortfolioGroups, getPortfolioData, getEvolucion, getEvolucionGroup, getComposicion, getComposicionGroup, getCotizacionesData };