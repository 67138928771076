import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Field } from "formik";

const FormTextField = ({ as, md, controlId, label, name, type, inputGroupPrepend, disabled }: any) => {
    return (
        <Field name={name} render={({ field, form, meta }: any) => {
            return (
                <Form.Group as={as} md={md} controlId={controlId} className="mb-3">
                    <Form.Label>{label}</Form.Label>
                    <InputGroup>
                        {inputGroupPrepend}
                        <Form.Control
                            {...field}
                            type={type}
                            value={meta.value}
                            onChange={e => {
                                form.setFieldValue(name, e.target.value);
                            }}
                            // isValid={meta.touched && !meta.error}
                            isInvalid={(meta.touched || form.submitCount > 0) && meta.error}
                            feedback={meta.error}
                            autocomplete='off'
                            disabled={disabled}
                        />
                        <Form.Control.Feedback type="invalid">
                            {meta.error}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            );
        }}
        />
    );
};

FormTextField.defaultProps = {
    type: "text",
    inputGroupPrepend: null
};

export default FormTextField;
