import { ModalActionType } from "./actions";
import { ModalStateType, ModalsActionTypes } from "./constants";


const INIT_STATE: ModalStateType = {
    currentZIndex: 10000
};

const Modals = (state: ModalStateType = INIT_STATE, action: ModalActionType<number>) => {
    switch (action.type) {
        case ModalsActionTypes.ADD_MODAL:
            return {
                ...state,
                currentZIndex: state.currentZIndex + 1,
            };
        case ModalsActionTypes.DELETE_MODAL:
            return {
                ...state,
                currentZIndex: state.currentZIndex - 1,
            };
        default:
            return state;
    }
};

export default Modals;
