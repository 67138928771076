import { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";

type FormCurrencyTranslatorValue = {
    currencyFrom?: string | undefined,
    currencyTo?: string | undefined,
};
type FormCurrencyTranslatorFieldProps = {
    label?: any;
    containerClass?: any;
    currencyTypes?: any[];
    value?: FormCurrencyTranslatorValue,
    onChange?: (cotizacion: any) => void;
    disabled?: boolean;
    required?: boolean;
};
const FormCurrencyTranslatorField = (props: FormCurrencyTranslatorFieldProps, ref: any) => {
    const [currencyFrom, setCurrencyFrom] = useState<string | undefined>(props.value?.currencyFrom);
    const [currencyTo, setCurrencyTo] = useState<string | undefined>(props.value?.currencyTo);

    const onChange = () => {
        if (props.onChange) {
            let newValue = {
                currencyFrom: props.currencyTypes?.filter(x => x.id == currencyFrom)[0],
                currencyTo: props.currencyTypes?.filter(x => x.id == currencyTo)[0]
            };

            props.onChange(newValue);
        }
    };
    useEffect(onChange, [currencyFrom, currencyTo]);

    const changeCurrencies = () => {
        setCurrencyFrom(currencyTo);
        setCurrencyTo(currencyFrom);
    };

    return (
        <Form.Group>
            <Form.Label htmlFor="Dropdown" className="w-100"><span>{props.label}</span></Form.Label>
            <InputGroup className="mb-3">
                <Form.Select
                    style={{ width: '150px' }}
                    value={currencyFrom}
                    as="select"
                    disabled={props.disabled}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        let newValue = e.currentTarget.value;
                        if (newValue == currencyTo) {
                            setCurrencyTo(currencyFrom);
                        }
                        setCurrencyFrom(newValue);
                    }}
                >
                    <option value=''>{props.currencyTypes?.length ? 'Seleccione...' : 'No existen items para seleccionar...'}</option>
                    {
                        props.currencyTypes?.map((x: any) => {
                            return (
                                <option key={x.id} value={x.id}>{x.description}</option>
                            );
                        })
                    }
                </Form.Select>
                <Button variant="outline-primary" className="text-dark btn btn-sm" onClick={changeCurrencies} disabled={props.disabled || !currencyTo || !currencyFrom}>
                    <i className='mdi mdi-arrow-left-right-bold'></i>
                </Button>
                <Form.Select
                    style={{ width: '150px' }}
                    value={currencyTo}
                    as="select"
                    disabled={props.disabled}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        let newValue = e.currentTarget.value;
                        if (newValue == currencyFrom) {
                            setCurrencyFrom(currencyTo);
                        }
                        setCurrencyTo(e.currentTarget.value);
                    }}                >
                    <option value=''>{props.currencyTypes?.length ? 'Seleccione...' : 'No existen items para seleccionar...'}</option>
                    {
                        props.currencyTypes?.map((x: any) => {
                            return (
                                <option key={x.id} value={x.id}>{x.description}</option>
                            );
                        })
                    }
                </Form.Select>
            </InputGroup>
        </Form.Group>
    );
};

FormCurrencyTranslatorField.defaultProps = {
    currencyTypes: [],
    disabled: false,
    value: {
        currencyFrom: '',
        currencyTo: '',
        price: null
    }
};

export default FormCurrencyTranslatorField;