import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from "./config";
import EINV_Providers from 'providers';

//Google analytics
if (config.REACT_APP_GA_MEASUREMENT_ID && ReactGA?.initialize) {
  //only on prod
  ReactGA.initialize(config.REACT_APP_GA_MEASUREMENT_ID);
}

ReactDOM.render(
  <EINV_Providers>
    <App />
  </EINV_Providers>,
  document.getElementById('root')
)

reportWebVitals();
