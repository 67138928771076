import { Provider } from 'react-redux';
import { configureStore } from 'redux/store';

const EINV_ReduxProvider = (props: any) => {
    return (
        <Provider store={configureStore({})}>
            {props.children}
        </Provider>
    )
}

export default EINV_ReduxProvider;