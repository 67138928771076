export type MenuItemType = {
    key: string;
    label: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemType[];
};

const MENU_ITEMS_COMERCIAL: MenuItemType[] = [
    {
        key: 'comercialDashboard',
        label: 'Dashboard',
        icon: 'uil-home-alt',
        url: '/comercial/dashboard',
    },
    {
        key: 'gestion',
        label: 'Gestión',
        isTitle: false,
        icon: 'uil-sliders-v-alt',
        children: [
            {
                key: 'clients',
                label: 'Clientes',
                url: '/comercial/gestion/clients',
                parentKey: 'gestion',
            },
            {
                key: 'users',
                label: 'Usuarios',
                url: '/comercial/gestion/users',
                parentKey: 'gestion',
            },
            {
                key: 'myuser',
                label: 'Mis Datos',
                url: '/comercial/gestion/myuser',
                parentKey: 'gestion',
            },
            {
                key: 'comercial',
                label: 'Comerciales',
                url: '/comercial/gestion/comercial',
                parentKey: 'gestion',
            },
        ],
    },
    {
        key: 'reporteria',
        label: 'Reporteria',
        isTitle: false,
        icon: 'uil-chart-line',
        children: [
            {
                key: 'cotizaciones',
                label: 'Cotizaciones',
                url: '/comercial/reports/cotizaciones',
                parentKey: 'reporteria',
            }
        ],
    },
    {
        key: 'ajustes',
        label: 'Ajustes',
        isTitle: false,
        icon: 'uil-sliders-v-alt',
        children: [
            // {
            //     key: 'parametry',
            //     label: 'Parametría',
            //     url: '/comercial/ajustes/parametry',
            //     parentKey: 'ajustes',
            // }
        ],
    },
];

const MENU_ITEMS_USERS: MenuItemType[] = [
    {
        key: 'landing',
        label: 'Home',
        isTitle: false,
        icon: 'uil-home-alt',
        parentKey: 'users',
        url: '/users/landing'
    },
    {
        key: 'gestion',
        label: 'Gestión',
        isTitle: false,
        icon: 'uil-package',
        url: '/users/gestion',
        children: [
            {
                key: 'movimientos',
                label: 'Movimientos',
                url: '/users/gestion/movimientos',
                parentKey: 'gestion',
            },
            {
                key: 'alta-manual',
                label: 'Registro de Operaciones',
                url: '/users/gestion/movimientos/alta-manual',
                parentKey: 'gestion',
            },
            {
                key: 'cotizaciones',
                label: 'Cotizaciones',
                url: '/users/gestion/cotizaciones',
                parentKey: 'gestion',
            }
        ]
    },
    {
        key: 'reporteria',
        label: 'Reporteria',
        isTitle: false,
        icon: 'uil-chart-line',
        children: [
            {
                key: 'posicion',
                label: 'Posición',
                url: '/users/reports/posicion',
                parentKey: 'reporteria',
            },
            {
                key: 'rendimiento',
                label: 'Rendimiento',
                url: '/users/reports/rendimiento',
                parentKey: 'reporteria'
            },
            {
                key: 'ctacte',
                label: 'Cuenta Corriente',
                url: '/users/gestion/ctacte',
                parentKey: 'gestion',
                badge: { variant: 'danger', text: 'Proximamente' }
            }            
        ],
    },
    {
        key: 'miPerfil',
        label: 'Configuración',
        isTitle: false,
        icon: 'mdi mdi-account-edit',
        children: [
            {
                key: 'myuser',
                label: 'Mis Datos',
                url: '/users/myuser',
                parentKey: 'miPerfil',
            },
            {
                key: 'myportfolio',
                label: 'Mis Carteras',
                url: '/users/myportfolio',
                parentKey: 'miPerfil',
            }//,
            // {
            //     key: 'parametry',
            //     label: 'Parametría',
            //     url: '/users/parametry',
            //     parentKey: 'miPerfil',
            // }
        ],
    }    
];

const MENU_ITEMS_HOLDER: MenuItemType[] = [
    {
        key: 'home',
        label: 'Home',
        isTitle: false,
        icon: 'uil-home-alt',
        url: '/accountHolder/home',
    },
    {
        key: 'gestion',
        label: 'Gestión',
        isTitle: false,
        icon: 'uil-package',
        url: '/accountHolder/gestion',
        children: [
            {
                key: 'movimientos',
                label: 'Movimientos',
                url: '/accountHolder/gestion/movimientos',
                parentKey: 'gestion',
            },
            {
                key: 'alta-manual',
                label: 'Registro de Operaciones',
                url: '/accountHolder/gestion/movimientos/alta-manual',
                parentKey: 'gestion',
            },
            {
                key: 'cotizaciones',
                label: 'Cotizaciones',
                url: '/accountHolder/gestion/cotizaciones',
                parentKey: 'gestion',
            }
        ]
    },
    {
        key: 'carteras',
        label: 'Carteras',
        isTitle: false,
        icon: 'uil-suitcase-alt',
        children: [
            {
                key: 'porfolios',
                label: 'Mis Carteras',
                url: '/accountHolder/carteras/porfolios',
                parentKey: 'carteras'
            },
            {
                key: 'porfolios_groups',
                label: 'Grupos de Carteras',
                url: '/accountHolder/carteras/porfolios_groups',
                parentKey: 'carteras',
            },
        ],
    },
    {
        key: 'reporteria',
        label: 'Reporteria',
        isTitle: false,
        icon: 'uil-chart-line',
        children: [
            {
                key: 'posicion',
                label: 'Posición',
                url: '/accountHolder/reports/posicion',
                parentKey: 'reporteria',
            },
            {
                key: 'rendimiento',
                label: 'Rendimiento',
                url: '/accountHolder/reports/rendimiento',
                parentKey: 'reporteria'
            },
            {
                key: 'ctacte',
                label: 'Cuenta Corriente',
                url: '/accountHolder/gestion/ctacte',
                parentKey: 'gestion',
                badge: { variant: 'danger', text: 'Proximamente' }
            }            
        ],
    },
    {
        key: 'miPerfil',
        label: 'Configuración',
        isTitle: false,
        icon: 'mdi mdi-account-edit',
        children: [
            {
                key: 'users',
                label: 'Usuarios',
                url: '/accountHolder/miPerfil/users',
                parentKey: 'miPerfil',
            },
            {
                key: 'myclient',
                label: 'Mi Cliente',
                url: '/accountHolder/miPerfil/myclient',
                parentKey: 'miPerfil',
            },
            {
                key: 'myuser',
                label: 'Mis Datos',
                url: '/accountHolder/miPerfil/myuser',
                parentKey: 'miPerfil',
            },
            {
                key: 'clientAccounts',
                label: 'Cuentas de Liquidación',
                url: '/accountHolder/miPerfil/clientAccounts',
                parentKey: 'miPerfil',
            }//,
            // {
            //     key: 'parametry',
            //     label: 'Parametría',
            //     url: '/accountHolder/miPerfil/parametry',
            //     parentKey: 'miPerfil',
            // }
        ],
    }    
];

const MENU_ITEMS_ADMIN: MenuItemType[] = [
    {
        key: 'landing',
        label: 'Dashboard',
        isTitle: false,
        icon: 'uil-home-alt',
        url: '/escoInversionesLanding'
    },
    {
        key: 'reportes',
        label: 'Reportes',
        isTitle: false,
        icon: 'uil-chart-pie-alt',
        url: '/reportes',
        children: [
            {
                key: 'cotizaciones',
                label: 'Cotizaciones',
                url: '/reportes/cotizaciones',
                parentKey: 'reportes',
            },
        ],
    },
    {
        key: 'historico',
        label: 'Historico',
        isTitle: false,
        icon: 'uil-money-stack',
        url: '/historico'
    },
    {
        key: 'ajustes',
        label: 'Ajustes',
        isTitle: false,
        icon: 'uil-sliders-v-alt',
        children: [
            // {
            //     key: 'parametria',
            //     label: 'Parametria',
            //     url: '/ajustes/parametry',
            //     parentKey: 'ajustes',
            // },
            {
                key: 'administracion',
                label: 'Administracion',
                parentKey: 'ajustes',
                children: [
                    {
                        key: 'clients',
                        label: 'Clientes',
                        url: '/ajustes/admin/clients',
                        parentKey: 'administracion',
                    },
                    {
                        key: 'miCliente',
                        label: 'Mi Cliente',
                        url: '/ajustes/admin/myclient',
                        parentKey: 'administracion',
                    },
                    {
                        key: 'usuarios',
                        label: 'Usuarios',
                        url: '/ajustes/admin/users',
                        parentKey: 'administracion',
                    },
                    {
                        key: 'myuser',
                        label: 'Mis Datos',
                        url: '/ajustes/admin/myuser',
                        parentKey: 'administracion',
                    },
                    {
                        key: 'carteras',
                        label: 'Mis Carteras',
                        url: '/ajustes/admin/porfolios',
                        parentKey: 'administracion'
                    },

                    {
                        key: 'comercial',
                        label: 'Comerciales',
                        url: '/ajustes/admin/comercial',
                        parentKey: 'administracion',
                    },
                ],
            },
            {
                key: 'escoinversiones',
                label: 'Esco Inversiones',
                url: '/ajustes/einv',
                parentKey: 'ajustes',
            },
        ],
    },
];

export { MENU_ITEMS_ADMIN, MENU_ITEMS_COMERCIAL, MENU_ITEMS_HOLDER, MENU_ITEMS_USERS };
