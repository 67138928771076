import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { AxiosResponse } from 'axios';

let currentQuery: string | null = null;

interface AsyncTypeaheadInputProps {
    ref?: any;
    handleSearch?: (query: string) => Promise<AxiosResponse<any, any>>;
    label?: any;
    name?: string;
    inputGroupPrepend?: any;
    formControlFeedback?: any;
    displayLabel?: any;
    renderMenuItemChildren?: any;
    onChange?: (selectedItem: any) => any;
    containerClass?: string;
    data?: any[];
    promptText?: string;
    size?: 'sm' | 'lg' | undefined;
    apiEndpoint?: (filters: any) => Promise<AxiosResponse<any, any>>;
    errors?: any;
    onComponentLoaded?: (componentRef: any) => void;
    delay?: number;
    defaultInputValue?: any;
    disabled?: boolean;
};

const AsyncTypeaheadInput = forwardRef((props: AsyncTypeaheadInputProps, ref: any) => {
    const defaultRef = useRef();
    const resolvedRef: any = null || defaultRef;

    //determina si se muestra el progress indicator de búsqueda
    const [isSearching, setIsSearching] = useState<boolean>(false);
    //Paginado de resultados... cuántos resultados estoy viendo?
    const [shownResults, setShownResults] = useState<number | null>(null);
    //Items
    const [items, setItems] = useState<any[]>(props.data || []);
    //Consulta actual. Se utiliza para determinar si el usuario ha ingresado caracteres nuevos mientras se está realizando la consulta remota
    // Bypass client-side filtering by returning `true`. Results are already filtered by the search endpoint, so no need to do it again.
    const filterBy = (a: any) => true;


    useImperativeHandle(ref, () => ({
        clear() {
            if (resolvedRef.current) {
                resolvedRef.current.clear();
            }
            setItems([]);
        },
        setSelectedItem(selectedItem: any) {
            if (selectedItem) {
                setItems([selectedItem]);
                resolvedRef.current.setState({
                    initialItem: selectedItem,
                    selected: [selectedItem]
                })
            }
        }
    }));

    //al iniciarse typeahead
    useEffect(() => {
        if (resolvedRef.current && props.onComponentLoaded) {
            props.onComponentLoaded(resolvedRef.current);
        }
    }, [resolvedRef.current]);

    const handleSearch = (query: string) => {
        if (props.apiEndpoint) {
            currentQuery = query;

            setIsSearching(true);
            props.apiEndpoint(query)
                .then((response: any) => {
                    if (query == currentQuery) {
                        setItems(response.data);
                        setShownResults(10);
                    }
                })
                .finally(() => {
                    if (query == currentQuery) {
                        if (!resolvedRef.current.isMenuShown) {
                            resolvedRef.current.toggleMenu();
                        }
                        setIsSearching(false);
                    }
                });
        }
    };

    function getDisplayLabel(arg0: any): string | undefined {
        throw new Error('Function not implemented.');
    }

    return (
        <Form.Group className={props.containerClass}>
            <Form.Label>{props.label}</Form.Label>
            <InputGroup>
                {props.inputGroupPrepend}
                <AsyncTypeahead
                    id={props.name}
                    ref={resolvedRef}
                    filterBy={filterBy}
                    isLoading={isSearching}
                    onSearch={handleSearch}
                    options={items}
                    paginate={true}
                    onPaginate={(e: any, _shownResults: number) => {
                        setShownResults(_shownResults);
                    }}
                    useCache={false}
                    maxResults={10}
                    paginationText={(<>
                        <div className='d-flex'>
                            <div className='flex-grow-1'>Mostrar más datos...</div>
                            <i>
                                Viendo {shownResults || 10} de {items?.length}
                            </i>
                        </div>
                    </>
                    )}
                    searchText={'Buscando...'}
                    clearButton={true}
                    promptText={isSearching ? 'Buscando...' : props.promptText}
                    emptyLabel={'No se encontraron resultados'}
                    size={props.size}
                    minLength={0}
                    onChange={(selectedOptions: any) => {
                        if (props.onChange) {
                            props.onChange(selectedOptions[0]);
                        }
                    }}
                    renderMenuItemChildren={props.renderMenuItemChildren}
                    defaultInputValue={props.defaultInputValue}
                    labelKey={(option: any) => { return props.displayLabel ? props.displayLabel(option) : null; }}
                    isInvalid={!!props.errors}
                    disabled={props.disabled}
                />
                {props.formControlFeedback}
            </InputGroup>
        </Form.Group>
    );
});

AsyncTypeaheadInput.defaultProps = {

};

export default AsyncTypeaheadInput;
