import { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { currencyFormat, defaultAreaOptions, defaultPieOptions } from './defaultChartsValues';
import { useTranslation } from 'react-i18next';
import { useRedux } from 'hooks';
import classNames from 'classnames';
import { PortFolioDetailType } from 'types/PortFolioDetailType';
import { PieChartInfoType } from 'types/PieChartInfoType';

type StatisticsChartWidgetCarteraProps = {
    detailInfo?: PortFolioDetailType;
    colors?: Array<string>;
    porcentaje?: number;
    areaDataSeries?: ApexAxisChartSeries,
    start?: Date | null,
    end?: Date | null,
    showDetailLink?: boolean,
};

const StatisticsChartCarteraWidget = ({
    detailInfo,
    colors,
    porcentaje,
    start,
    end,
    showDetailLink
}: StatisticsChartWidgetCarteraProps) => {
    const { t } = useTranslation();
    const { appSelector } = useRedux();
    const { clientAvailable, userRole } = appSelector((state) => ({
        clientAvailable: state.Global.clientAvailable,
        userRole: state.Global.userRole,
    }));

    let pieOpts: ApexOptions = getPieOptions(detailInfo?.composicionByActivo || []);
    let pieOptsTiposActivo: ApexOptions = getPieOptions(detailInfo?.composicionByTpActivo || []);
    
    let areaOptions: ApexOptions = {
        ...defaultAreaOptions,
        series: getData(detailInfo || {}),
        fill: {
            ...defaultAreaOptions.fill,
            colors: colors,
        },
        yaxis: {
            ...defaultAreaOptions.yaxis,
            title: {
                text: t('Valuacion')
            }
        },
        xaxis: {
            ...defaultAreaOptions.xaxis,
            title: {
                text: t('Evolucion')
            }
        },
    };

    function getPieOptions(pie: PieChartInfoType[]): ApexOptions {
        return {
            ...defaultPieOptions,
            labels: pie.map(x => x.name || ''),
            series: pie.map(x => x.value || 0),
        };
    }
    function getData(detail: PortFolioDetailType): ApexAxisChartSeries {
        let values = detail?.evolution?.map(info => {
            return {
                x: info.date,
                y: info.value
            };
        });

        return [{
            name: detail?.name,
            color: colors ? colors[0] : '',
            data: values as { x: any; y: any; fillColor?: string; strokeColor?: string; meta?: any; goals?: any; }[]
        }];
    }

    const [agrupadoPorTpActivo, setagrupadoPorTpActivo] = useState<boolean>(true);;
    function onClickChangeActiveType() {
        setagrupadoPorTpActivo(!agrupadoPorTpActivo);
    }

    function renderCharts() {
        let params = `?carteraId=${detailInfo?.id}&start=${moment(start).toISOString()}&end=${moment(end).toISOString()}`;
        return (
            <>
                <Row className="align-items-center p-1">
                    <Col className="col-8 text-md-start">
                        <h4 className="my-2 py-1">{detailInfo?.name}: {currencyFormat(detailInfo?.valuacionTotal)}</h4>
                    </Col>
                    <Col className="col-4 text-end">
                        <Link to="#" className={classNames('btn btn-primary ms-1', clientAvailable ? '' : 'disabled')}>
                            <i className="mdi mdi-piggy-bank-outline font-13"></i> {t('Registrar operacion')}
                        </Link>
                        {
                            showDetailLink && (
                                <Link to={{ pathname: `/carteraDetail`, search: params }} className="btn btn-primary ms-1 ">
                                    {t('Detalle')} <i className="mdi mdi-arrow-right"></i>
                                </Link>
                            )
                        }

                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col className="col-12">
                        <Row>
                            <Col className="col-8">
                                <Chart
                                    className="apex-charts"
                                    options={areaOptions}
                                    series={areaOptions.series}
                                    height={300}
                                    type='area'
                                />
                            </Col>
                            <Col className="col-4">
                                <div className="w-100">
                                    <Form.Check
                                        type="switch"
                                        id={detailInfo?.name + "-switch"}
                                        className="w-300 text-start"
                                        style={{
                                            marginLeft: "auto",
                                            marginRight: "auto"
                                        }}
                                        label={agrupadoPorTpActivo ? t('Agrupado por Tipo de activo') : t('Agrupado por Activo')} onClick={onClickChangeActiveType} />
                                </div>
                                <Chart
                                    options={agrupadoPorTpActivo ? pieOptsTiposActivo : pieOpts}
                                    series={agrupadoPorTpActivo ? pieOptsTiposActivo.series : pieOpts.series}
                                    type="pie"
                                    height={200}
                                    className="apex-charts mb-4 mt-4"
                                />
                            </Col>
                        </Row>
                        <Row className="text-center p-3">
                            <Col className="col-8">
                                <span>{t('Resultado del periodo')}: {currencyFormat(detailInfo?.resultadoPeriodo)}</span>
                            </Col>
                            <Col className="col-4">
                                <span>{t('Resultado diario')}: {currencyFormat(detailInfo?.resultadoDiario)}
                                    {
                                        porcentaje && (
                                            <span className="mb-0 text-muted">
                                                <span className="me-2">
                                                    {porcentaje}
                                                </span>
                                            </span>
                                        )
                                    }
                                </span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }

    return renderCharts();
};

export default StatisticsChartCarteraWidget;
