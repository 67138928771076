import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Field } from "formik";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { NumberFormatOptions } from '@progress/kendo-react-intl';

const FormNumericField = ({ as, md, controlId, label, name, type, inputGroupPrepend, disabled, onChange, spinners }: any) => {
    const formatOptions: NumberFormatOptions = {
        style: "decimal",
        maximumFractionDigits: 8
    };

    return (
        <Field name={name} render={({ field, form, meta }: any) => {
            return (
                <Form.Group as={as} md={md} controlId={controlId} className="mb-3">
                    <Form.Label>{label}</Form.Label>
                    <InputGroup>
                        {inputGroupPrepend}
                        <NumericTextBox
                            className='einv-kendo-form-control'
                            name={name}
                            value={meta.value}
                            min={0}
                            format='n4'
                            inputType='text'
                            fillMode='outline'
                            rounded='small'
                            disabled={disabled}
                            spinners={spinners !== undefined ? spinners : false} //EINV-612 -> se previene que se incremente/decremente con botones
                            step={0} //EINV-612 -> se previene que se incremente/decremente con keyPress
                            onChange={e => {
                                form.setFieldValue(name, e.target.value || '');
                                if (onChange) {
                                    onChange(e);
                                }
                            }}
                            onBlur={() => {
                                form.getFieldHelpers(name).setTouched(true, false);
                            }}
                            valid={!((meta.touched || form.submitCount > 0) && meta.error)}
                        />
                        <Form.Control.Feedback type="invalid" style={{ display: meta.error ? 'block' : 'none' }}>
                            {meta.error}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            );
        }}
        />
    );
};

FormNumericField.defaultProps = {
    type: "text",
    inputGroupPrepend: null
};

export default FormNumericField;
