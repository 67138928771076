import { RolesEnum } from 'types/Roles';
import { GlobalsActionTypes } from './constants';
import { CurrencyItem } from '../../layouts/types';

export type GlobalActionType<TPayload> = {
    type:
    | GlobalsActionTypes.SET_CLIENT_AVAILABLE
    | GlobalsActionTypes.SET_CLIENT_NAME
    | GlobalsActionTypes.SET_USER_ROLE
    | GlobalsActionTypes.SET_CLIENT_CURRENCY_TYPE
    | GlobalsActionTypes.SET_CLIENT_COUNTRY
    | GlobalsActionTypes.SET_AVAILABLE_CURRENCIES
    payload?: TPayload;
};

export const setClientAvailable = (isAvailable: boolean): GlobalActionType<boolean> => ({
    type: GlobalsActionTypes.SET_CLIENT_AVAILABLE,
    payload: isAvailable
});

export const setClientName = (clientName: string): GlobalActionType<string> => ({
    type: GlobalsActionTypes.SET_CLIENT_NAME,
    payload: clientName
});

export const setClientCurrencyType = (clientCurrencyType: string): GlobalActionType<string> => ({
    type: GlobalsActionTypes.SET_CLIENT_CURRENCY_TYPE,
    payload: clientCurrencyType
});

export const setClientCounty = (clientCountryId: number): GlobalActionType<number> => ({
    type: GlobalsActionTypes.SET_CLIENT_COUNTRY,
    payload: clientCountryId
});

export const setUserRole = (role: string): GlobalActionType<RolesEnum> => ({
    type: GlobalsActionTypes.SET_USER_ROLE,
    payload: RolesEnum[role as keyof typeof RolesEnum]
});

export const setAvailableCurrencies = (currencies: CurrencyItem[]): GlobalActionType<CurrencyItem[]> => ({
    type: GlobalsActionTypes.SET_AVAILABLE_CURRENCIES,
    payload: currencies,
});

export default { setClientAvailable, setClientName, setUserRole, setClientCurrencyType, setClientCounty, setAvailableCurrencies };


