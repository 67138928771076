import { ThemeSwitcherProvider } from 'react-css-theme-switcher';

const EINV_ThemeProvider = (props: any) => {
    const themes = {
        dark: `${process.env.PUBLIC_URL}/_kendo-react-custom-variables_dark.css`,
        light: `${process.env.PUBLIC_URL}/_kendo-react-custom-variables_light.css`,
    };

    return (
        <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme="dark"
            insertionPoint="styles-insertion-point"
        >
            {props.children}
        </ThemeSwitcherProvider>
    )
}

export default EINV_ThemeProvider;