import { NotificationActionType } from "./actions";
import { NotificationStateType, NotificationActionTypes, NotificationData } from "./constants";


const INIT_STATE: NotificationStateType = {
    notifications: []
};

const Notification = (state: NotificationStateType = INIT_STATE, action: NotificationActionType<NotificationData>) => {
    switch (action.type) {
        case NotificationActionTypes.ADD_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications, action.payload],
            };
        case NotificationActionTypes.DELETE_NOTIFICATION:
            var array = [...state.notifications]; // make a separate copy of the array
            if (action?.payload) {
                var index = array.indexOf(action.payload);
                if (index !== -1) {
                    array.splice(index, 1);
                }
            }
            return {
                ...state,
                notifications: array,
            };
        default:
            return state;
    }
};

export default Notification;
