export enum RolesEnum {
    Admin = 'Admin',
    Comercial = 'Comercial',
    AccountHolder = 'AccountHolder',
    Users = 'Users',
}

export const RolesNumber  = [ 'Admin', 'Comercial', 'AccountHolder', 'Users']

export type RoleType = {
    id: number,
    name?: string,
    description?: string
};