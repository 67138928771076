import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { useApiCalls as api } from 'helpers/api/useApiCalls';
import { getPortfolioResume, getPortfolioDetail, getPortfolioGroups, getEvolucion, getEvolucionGroup, getComposicion, getComposicionGroup, getCotizacionesData } from './portfolio_fake-data';

type User = {
    id: string;
    email?: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
};

const TOKEN =
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI';

var mock = new MockAdapter(axios, { onNoMatch: 'passthrough' });

export function configureFakeBackend() {
    let users: User[] = [
        {
            id: '1',
            username: 'test',
            password: 'test',
            firstName: 'Test',
            lastName: 'User',
            role: 'Admin',
            token: TOKEN,
        },
    ];

    mock.onPost('/login/').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);

                // find if any user matches login credentials
                let filteredUsers = users.filter((user) => {
                    return user.username === params.username && user.password === params.password;
                });

                if (filteredUsers.length) {
                    // if login details are valid return user details and fake jwt token
                    let user = filteredUsers[0];
                    resolve([200, user]);
                } else {
                    // else return error
                    resolve([401, { message: 'Username or password is incorrect' }]);
                }
            }, 1000);
        });
    });

    mock.onPost('/register/').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);

                // add new users
                let [firstName, lastName] = params.fullname.split(' ');
                let newUser: User = {
                    id: (users.length + 1).toString(),
                    username: firstName,
                    password: params.password,
                    firstName: firstName,
                    lastName: lastName,
                    role: 'Admin',
                    token: TOKEN,
                };
                users.push(newUser);

                resolve([200, newUser]);
            }, 1000);
        });
    });

    mock.onPost('/forget-password/').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);

                // find if any user matches login credentials
                let filteredUsers = users.filter((user) => {
                    return user.username === params.username;
                });

                if (filteredUsers.length) {
                    // if login details are valid return user details and fake jwt token
                    let responseJson = {
                        message: "We've sent you a link to reset password to your registered email.",
                    };
                    resolve([200, responseJson]);
                } else {
                    // else return error
                    resolve([
                        401,
                        {
                            message: 'Sorry, we could not find any registered user with entered username',
                        },
                    ]);
                }
            }, 1000);
        });
    });

    mock.onPost('/getPortfolioData').reply(function (config) {
        return new Promise(function (resolve, reject) {
            const { active, Portfolio, Groups } = api();
            setTimeout(async function () {
                const response = await Portfolio.get();
                let portfolios = response?.data?.filter((x:any) => x.client?.id == active().codCliente && x.isAvailable);
                let params = JSON.parse(config.data);
                if (params.params) {
                    params = params.params;
                }
                let retorno = getPortfolioResume(params.start, params.end, portfolios);
                resolve([200, retorno]);
            }, 0);
        });

        // return new Promise(function (resolve, reject) {
        //     setTimeout(function () {
        //         // get parameters from post request
        //         let params = JSON.parse(config.data);
        //         if (params.params) {
        //             params = params.params;
        //         }
        //         //data filtering
        //         let data = getPortfolioResume(params.start, params.end, params.portfolioIds);

        //         //resolve
        //         resolve([200, data]);
        //     }, 500);
        // });
    });

    mock.onPost('/getPortfolioDataDetail').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);
                if (params.params) {
                    params = params.params;
                }
                //data filtering
                let data = getPortfolioDetail(params.carteraId, params.start, params.end);

                //resolve
                resolve([200, data && data.length ? data[0] : []]);
            }, 500);
        });
    });

    mock.onPost('/getPortfolioGroups').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);
                if (params.params) {
                    params = params.params;
                }
                //data filtering
                let data = getPortfolioGroups(params.id);

                //resolve
                resolve([200, data && data.length ? data : []]);
            }, 3000);
        });
    });

    mock.onPost('/portfolio/evolucion').reply(function (config) {
        return new Promise(function (resolve, reject) {
            const { active, Portfolio, Groups } = api();
            setTimeout(async function () {
                // get parameters from post request
                let params = JSON.parse(config.data);
                if (params.params) {
                    params = params.params;
                }
                //data filtering
                let data = getEvolucion(params.portfolioId, params.start, params.end);
                resolve([200, data]);
            }, 1000);
        });
    });

    mock.onPost('/groups/evolucion').reply(function (config) {
        return new Promise(function (resolve, reject) {
            const { active, Portfolio, Groups } = api();
            setTimeout(async function () {
                // get parameters from post request
                let params = JSON.parse(config.data);
                if (params.params) {
                    params = params.params;
                }
                //data filtering
                let data = getEvolucionGroup(params.groupId, params.start, params.end);
                resolve([200, data]);
            }, 700);
        });
    });

    mock.onPost('/portfolio/composicion').reply(function (config) {
        return new Promise(function (resolve, reject) {
            const { active, Portfolio, Groups } = api();
            setTimeout(async function () {
                // get parameters from post request
                let params = JSON.parse(config.data);
                if (params.params) {
                    params = params.params;
                }
                //data filtering
                let data = getComposicion(params.portfolioId, params.start, params.end);
                resolve([200, data]);
            }, 500);
        });
    });

    mock.onPost('/groups/composicion').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(async function () {
                // get parameters from post request
                let params = JSON.parse(config.data);
                if (params.params) {
                    params = params.params;
                }
                //data filtering
                let data = getComposicionGroup(params.groupId, params.start, params.end);
                resolve([200, data]);
            }, 600);
        });
    });

    mock.onPost('/portfolio/indicadores').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(async function () {
                resolve([200, {}]);
            }, 600);
        });
    });

    mock.onPost('/groups/indicadores').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(async function () {
                resolve([200, {}]);
            }, 600);
        });
    });
}